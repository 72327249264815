import styles from "../../styles/faculty/teachingStaff.module.css";
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { facultyData } from "../../metadata/facultyData"

const FacultyCard = ({ data }) => {
    
  return (
    <div className={styles.cardContainer}>
      {data.faculties.map((item, index) => (
        <Link key={index} to={`/faculty/${item.id}`} className={styles.rrdLink}>
          <div className={styles.card}>
            <img src={`data:image/jpeg;base64, ${item.image}`} alt="" className={styles.cardImg} />
            <div className={styles.cardData}>
              <span className={styles.cardName}> {item.name} </span>
              <span className={styles.designation}> {item.designation} </span>
              <span className={styles.qualification}> {item.qualification} </span>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default function TeachingStaff(props) {
  const [data, setData] = useState(null);
  const baseUrl = "https://cse.spit.ac.in/api"

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/endpoints/faculty_list`);
      setData(response.data);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  if (!data) {
    return <div>loading</div>;
  }

  return (
    <div>
      <div className={styles.topBanner}>
        <span className={styles.bannerTitle}> List of Faculties </span>
      </div>
      <div className={styles.container}>
        <h2 className={styles.facultyHeading}> Faculties for CSE </h2>
        <FacultyCard data={data} />
      </div>
      <div className={styles.container}>
        <a
          href="https://mca.spit.ac.in/index.php/facult/"
          rel="noreferrer"
          target="_blank"
          className={styles.facultyHeadingMCA}
        >
          {" "}
          Faculties for MCA <FiExternalLink />{" "}
        </a>
      </div>
    </div>
  );
}
