import React, { useState, useEffect, useRef } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { RiMenu3Fill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';
// import { data } from '../metadata/aboutDeptData';
import { menuItems } from '../metadata/menuItems';  
import { Link } from 'react-router-dom';
// CSS for this page present in App.css

function Header() {

	// const [impLinksDropdownFlag, setimpLinksDropdownFlag] = useState(false); // false means dropdown closed

	// const toggleImpLinksDropdown = () => {
	// 	setimpLinksDropdownFlag(!impLinksDropdownFlag);

	// 	if(impLinksDropdownFlag === false) {
	// 		setMobileOpen(false);
	// 	}
	// }

	const [dropdown, setDropdown] = useState([false, false, false]);
	const [scrolled, setScrolled] = useState(false);//maaz

	useEffect(() => {
		const handleScroll = () => {
		  if (window.scrollY > 40) {
			setScrolled(true);
		  } else {
			setScrolled(false);
		  }
		};
	
		window.addEventListener("scroll", handleScroll);
	
		return () => {
		  window.removeEventListener("scroll", handleScroll);
		};
	  }, []);//maaz

	const clearDropdown = () => {
		setDropdown([false, false, false])
	}

	const updateDropdown = (index) => {
		let updated = [...dropdown];
		if(updated[index-1] === true) {
			clearDropdown();
		} else {
			let newValues = [false, false, false];
			newValues[index - 1] = !newValues[index - 1];
			setDropdown(newValues);	
		}
	}

	const [mobileOpen, setMobileOpen] = useState(false);
	const handleHamburgerClick = () => {
		setMobileOpen(!mobileOpen);

		// if(mobileOpen === false) {
		// 	setimpLinksDropdownFlag(false);
		// }
	}

	let menuRef = useRef();

	useEffect(() => {
		let handler = (e) => {
			if (!menuRef.current.contains(e.target)) {
				clearDropdown();
			}
		};

		document.addEventListener("mousedown", handler);

		return () => {
			document.removeEventListener("mousedown", handler);
		}

	});

	return(
		<div id="header">
			<div id="topbar">
				{/* Important Links in Topbar Commented */}
				{/* <div id="dropdown">
					<div id="dropdownDisplayInfo" onClick={toggleImpLinksDropdown}>
						<div id="dropdownTitle"> Important Links </div>
						<FaCaretDown />
					</div>
					<ul id="dropdownMenu" className={impLinksDropdownFlag === true ? 'dropdownMenuOpen' : ''}>
						{
							data["important-links"] && data["important-links"].map((item, index) => (
								<li key={index} id="dropdownItem">
									<Link to={item.link} rel="noreferrer" id="dropdownItemLink" onClick={toggleImpLinksDropdown}> {item.title} </Link>
								</li>
							))
						}
					</ul>
				</div> */}
			</div>
			{/*maaz*/}
			<nav  id={scrolled ? "navbarScrolled" : "navbar"} ref={menuRef}>
				<Link to="https://www.spit.ac.in/" id="logoContainer">
					<img src="/assets/misc/clg-logo.png" alt="" id="logo" />
				</Link>
				<section>
					<span className="hamburger" onClick={() => {handleHamburgerClick(); clearDropdown();}}> { mobileOpen ? <RxCross2 /> : <RiMenu3Fill /> }  </span>
					<ul className={`menus ${mobileOpen ? "menus-open" : ""}`}>
						{menuItems.map((items, index) => (
							<li className="menu-items" key={index}>
								{items.submenu ? (
									<>
										<button aria-expanded={dropdown[items.id-1] ? "true" : "false"}
											onClick={() => updateDropdown(items.id)}>
											{items.title} <FaCaretDown className='navbar-down-arrow' /> {' '}
										</button>
										<ul className={`dropdown ${dropdown[items.id - 1] ? "show" : ""}`}>
											{items.submenu.map((submenu, index) => (
												<li key={index} className="menu-items">
													<Link to={submenu.url} onClick={() => { clearDropdown(); handleHamburgerClick ();}}>{submenu.title}</Link>
												</li>
											))}
										</ul>
									</>
								) : (
										<Link to={items.url} onClick={() => { clearDropdown(); handleHamburgerClick();}}>{items.title}</Link>
								)}
							</li>
						))}
					</ul>
				</section>
			</nav>
		</div>
	)
}

export default Header;