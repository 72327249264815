import '../styles/home.css';
import { data } from '../metadata/aboutDeptData';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import  React,{useState,useEffect} from 'react';


const createCarouselItemImage = (imgFile, options = {}) => (
    <div key={imgFile}>
        <img src={`/assets/carousel/${imgFile}`} alt="" id="sliderImages" />
    </div>
);

const baseChildren = <div>{data["about-us-carousal-images"].map(createCarouselItemImage)}</div>;

const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: true,
    emulateTouch: true,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 6000,
    transitionTime: 1000,
    swipeScrollTolerance: 5
});

export const base = () => <Carousel {...getConfigurableProps()}>{baseChildren.props.children}</Carousel>;

export default function Home(){

    const [homepageData, setHomepageData] = useState(null);
    const baseUrl = "https://cse.spit.ac.in/api"
    const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}/endpoints/homepage`);
          setHomepageData(response.data);
        } catch (error) {
        //   console.error('Error fetching data:', error);
        }
      };
      
      useEffect(() => {
        fetchData();
      }, []);

      useEffect(() => {
        // console.log(homepageData);
      }, [homepageData]);

      if (!homepageData) {
        return <div>loading</div>;
    }

    return(
        <div id="home">
            <div id="welcomeContainer">
                <span id="welcome"> Welcome to </span>
                <span id="department"> Department of {homepageData.department}</span>
                <span id="college"> Sardar Patel Institute of Technology </span>
            </div>
            <div id="aboutContainer">
                <div id="leftAboutPart">
                    <div id="wrapper">
                        <Carousel {...getConfigurableProps()}>
                            {baseChildren.props.children}
                        </Carousel>
                    </div>
                    <div id="aboutDeptContent">
                        {/*
                            data["about-the-department"].map((line, index) => (
                                <span key={index} id="para"> {line} </span>
                            ))
                        */}
                        { homepageData.about_dept.split('\r\n\r\n').map((paragraph, index) => (
        <span key={index} id="para">{paragraph}</span>
    ))}
                    </div>
                </div>
                <div id="rightAboutPart">
                    <div id="quick-links-section">
                        <h2 id="quick-links-heading"> Quick Links </h2>
                        <ul id="quick-links-list">
                            {
                                data["important-links"] && data["important-links"].map((item, index) => (
                                    <li key={index} id="quick-links-item">
                                        <Link to={item.link} rel="noreferrer" id="quick-links-link"> {item.title} </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div id="hodMessageContainer">
                <div id="hod-message">
                    <div id="hod-message-head">
                        <div id="hod-message-head-img">
                        <img src={`data:image/jpeg;base64, ${homepageData.HOD_profile_image}`} alt="" />
                        </div>
                        <div id="hod-message-head-text">
                            <div id="hod-message-title">
                                <p>
                                    {data["message-from-hod"].title}
                                </p>
                                <p>
                                    <strong>{homepageData.HOD_name}</strong>
                                </p>
                            </div>
                            <div id="hod-message-subtitle">
                                {data["message-from-hod"].designation}
                            </div>
                        </div>
                    </div>
                    <div id="hod-message-desc">
                        <i className="fa-solid fa-quote-left"></i>
                        {/*
                            data["message-from-hod"].message.map((line, index) => (
                                <span key={index}>
                                    {line}
                                </span>
                            ))
                        */}
                        { homepageData.HODs_message.split('\r\n\r\n').map((paragraph, index) => (
        <span key={index} id="para">{paragraph}</span>
    ))}
                        <i className="fa-solid fa-quote-right right"></i>
                    </div>
                </div>
            </div>
            <div className='programContent'>
                <span className="programOffered"> Programs offered by the department </span>
                <div className="cardContainer">
                    <div className="card">
                        <span className='programNumber'> 01 </span>
                        <span className="programTitle"> CSE </span> <br />
                        <Link to="/academics" rel="noreferrer" className="knowMore"> Know More </Link>
                    </div>
                    <div className="card">
                        <span className='programNumber'> 02 </span>
                        <span className="programTitle"> MCA </span> <br />
                        <Link to="https://mca.spit.ac.in/" rel="noreferrer" target="_blank" className="knowMore"> Know More </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}