import { useEffect, useState } from "react";
import { academics } from "../../metadata/academicsData";
import axios from 'axios';
import styles from "../../styles/academics/academics.module.css";
import styles1 from "../../styles/faculty/facultyinfo.module.css";


const Others = () => {

    const [data, setData] = useState("");
    const baseUrl = "https://cse.spit.ac.in/api"


    const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}/generals/others`,{});
          // console.log("response", response.data)
          setData(response.data);
        } catch (error) {
          // console.error("Error fetching data:", error);
        }
      };

    useEffect(() => {
        fetchData()
    }, []);

    return (
      <div>
        <h2> Others </h2>
        <br />
        {data !== "" &&
          data.map((item, index) => (
            <>
              <br />
  
              <div className={styles.vmCard}>
              <div
                  className="image-container"
                  style={{
                    width: '100%',
                    maxHeight: '300px',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={`data:image/jpeg;base64, ${item.photo}`}
                    alt={item.name}
                    style={{
                      maxWidth: '95%',
                      height: '300px',
                      objectFit: 'scale-down'
                    }}
                  />
                </div>
                <div className="card-body">
                  <h2>{item.name}</h2>
                  <br />
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    {item.achievement}
                  </p>
                  <br />
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    {item.details}
                  </p>
                  <br />
                </div>
                
              </div>
            </>
          ))}
      </div>
    );
}

export default Others;