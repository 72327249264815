import { useEffect, useState } from "react";
import { academics } from "../../metadata/academicsData";
import styles from '../../styles/academics/academics.module.css'
import axios from 'axios';


const HigherStudies = () => {

    const [data, setData] = useState("");
    const baseUrl = "https://cse.spit.ac.in/api";


    const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}/generals/higher_studies`,{});
        //   console.log("response", response.data)
          setData(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <div>
            <h2> Higher Studies </h2>
            <br />
            {
                data !== "" && data.map((item, index) => (
                    <ul className={styles.academicLinks} key={index}>
                        <li> <a href={baseUrl+'/api/files/'+item.pdf.replace('/resources/resources/', '')} target="_blank" rel="noreferrer"> {item.year} </a> </li>
                    </ul>
                ))
            }
        </div>
    );
}

export default HigherStudies;