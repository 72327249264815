export const facultyData = [
  {
      id: "dr-dhananjay-kalbande",
      name: "Dr. Dhananjay Kalbande",
      image: "/assets/faculty/dr-dhananjay-kalbande.jpg",
      designation: "Professor and Head of Department",
      qualification: "Post-Doctorate (TISS), Ph.D.,M.E.(I.T.), B.E.(Comp.)",
      education: [
        "Post-Doctorate (TISS), Ph.D.,M.E.(I.T.), B.E.(Comp.)",
        "Senior Research Fellow (NCW-TISS Project,T.I.S.S., Mumbai)",
        "Research Fellow (T.I.S.S. and M.I.T.(USA), CLIx-Project, Mumbai)",
      ],
      contact: [
        "Email: drkalbande@spit.ac.in Tel: +91 (22) – 26707440 Ext:366",
        "Room No.608, 6th Floor,",
        "Department of Computer Science and Engineering, Sardar Patel Institute of Technology, Andheri(W), Mumbai 400058.",
      ],
      resume: "/assets/faculty/drkalbande-resume.pdf",
      data: {
        "Post Ph.D Collaborative Research Completed": [
          [
            "Research work Title : Skin Image Analysis Software to recognize skin diseases:- A clinical study to recognize skin diseases at the early stage.",
            "Principal Investigator and Guide: Dr.Uday Khopkar,Professor & Head, Dept.of Dermatology,Seth G.S.Medical College and KEM Hospital.",
            "Co-Investigators : Dr.Dhananjay Kalbande,Dr.Mithali Jage",
            "Results of the Investigation are approved from Institutional Ethics Committee(IEC Committee) ,Seth G.S.Medical College & KEM Hospital.",
            "Status : Completed",
          ],
          [
            "Research work Title : Skin Image Analysis Software for diagnosis of chronic pruritic Skin Diseases at early stage.",
            "Principal Investigator and Guide: Dr.Uday Khopkar,Professor & Head, Dept.of Dermatology,Seth G.S.Medical College and KEM Hospital.",
            "Co-Investigators : Dr.Dhananjay Kalbande,Dr. Akansha Chadha,Dr. Bhushan Darkase, Dr. Naziya M , Junior Resident, Seth G.S.Medical College & KEM Hospital.",
            "Protocol is submitted to Institutional Ethics Committee(IEC Committee) ,Seth G.S.Medical College & KEM Hospital.",
            "Status : In Process",
          ],
          [
            "MIS Project: MIS Application for Skin and VD Department, KEM Hospital, Parel , Mumbai",
          ],
        ],
        "Visiting Faculty": [
          "Worked as Guest Faculty (Artificial Intelligence and Deep Learning) at Lal Bahadur Shastri Institute of Management, Delhi from 22nd Oct 2020 to 15th Jan 2021 (Online Mode)",
        ],
        "Certification Done": [
          "GCP Certificate: NIDA Clinical Trials Network CTN Expiration Date: 24 March 2022",
          "GCP: Good Clinical Practices : Course Completion Date: 22 May 2015 ,CTN Expiration Date: 22 May 2018.",
        ],
        "Patent Filed – 09": [
          "Title of the Invention: “Skin Image Analysis Software for Skin Diseases” dated 24/02/2016 Time: 17:02:14, Docket No.4737.Application No.:201621006433",
          "Title of the Invention :“AUTO-WALAS: An App for Vehicle Management” dated 21/03/2016 Time: 16:03:00, Docket No. 7129, Application No.: 201621009763.",
          "Title of the Invention : Theoretical Concept Of “Business Process Framework In An Enterprise Architecture As Per The Architectural Level” dated 13/05/16 Application No. 201621011091 A.",
          "Title of the Invention for joint patent with T.I.S.S.,Mumbai for a Post-Doctorate work for the Invention “ Mobile App for Prasutitikakaran and Tikakaran” published dated 26/08/16 Application No.201621026972 A.",
          "Title of the Invention “F-MIOTH: Framework For Medical Iot (Internet Of Things In Healthcare” published dated 07/09/2018 Application No. 201821031554.",
          "Title of the Invention “Skinzerflow : Skin Disease Detection Using Iot And Image Processing” published dated 07/09/2018 ,Application No. 201821032115.",
          "Title of the Invention : Big Data Architecture For Predicting Malaria And Dengue Incidence., published dated: 29/11/2019, Application No. 201921046529",
          "Title of the Invention :Track on Park: Application Challan :TR-5, docket no, 65863,Application No.202021051448 dated 26/11/2020.",
          "Title of the Invention: Psychological distress detection,classification and assistance using AI, Application No.202121002700 dated 20/01/2021.",
        ],
        "Area of Interests": [
          "Soft Computing",
          "Machine Learning",
          "Deep Learning",
          "Human Machine Interaction",
          "User Experience Design",
          "Artificial Intelligence for Social and Healthcare cause",
          "Data and Image Analytics",
          "IOT for Rural Healthcare",
        ],
        "Professional Affiliations": [
          "Recognized PG Teacher & Ph.D.Guide of Mumbai University in Computer Engineering and Information Technology.",
          "List of research topics currently guiding as part of the academic research program leading to the award of PhD Degree in Computer Engineering and Information Technology",
        ],
        "National Journal": [
          "D.R.Kalbande, Dr.G.T.Thampi, S.Sawalekar,” TPM “Total productive Maintenance“: A case study in Manufacturing Industry”, IIIE (National Journal of Indian Institute of Industrial Engineering) Journal,India Oct .2010.",
          "Anjali Shrikant Yeole and Dhananjay Ramrao Kalbande , “IoT Technology Stack: Whole Host of Technology”, CSI Communications, Volume No 44 , Issue No. 1, April 2020 , special issue on Internet of things",
        ],
        "International Journal": [
          "D.R.Kalbande, Dr.G.T.Thampi, “Multi-attribute and Multi-criteria Decision Making Model for technology selection using fuzzy logic”, Technia – International Journal of Computing Science and Communication Technologies, IJCSCT Vol. 2, Issue 1, India ,July 2009.",
          "D.R.Kalbande, Manish Singh ,“Incidence Handling & Response System”, International Journal of Computer Science & Information Security IJCSIS, Vol. 2, June 2009, ISSN 1947-5500.( IMPACT FACTOR: 0.423)",
          "D.R.Kalbande, Dr.G.T.Thampi ”Fuzzy neural model (FNM) for selecting ERP Technologies in a ongoing project settings”, Issue in CiiT International Journal of Artificial Intelligent Systems and Machine Learning Print: ISSN 0974 – 9667 & Online: ISSN 0974 – 9543, Nov. 2009 , (DOI: AIML112009005, IMPACT FACTOR: 0.763)",
          "Shekhar Shah,D.R.Kalbande,Rudani,Presha Shah ,“Algorithm to access Office Files on Mobile Phones, Proceedings of International Journal of Computer Application (IJCA), ISSN: 0975 8887, Number 3, Article -4 March 2011 and also Published by Foundation of Computer Science. BibTeX",
          "D.R.Kalbande, Dr.G.T.Thampi , ”An Empirical Formulation of cost of technology acquisition and cost reduction: A case study in Manufacturing Industries ,International Journal of Industrial Engineering and Technology (IJIET), March 2011., ISSN 0974 -3146 Volume 3, Number 2(2011),pp.215-222.",
          "D. Kalbande, N. Deotale, P. Singhal, S. Shah, and G. Thampi, “An Advanced Technology Selection Model using Neuro Fuzzy Algorithm for Electronic Toll Collection System”, (IJACSA) International Journal of Advanced Computer Science and Applications, Vol. 2, No. 4, 2011 ,pp.97-104. Impact Factor: 1.324",
          "D.R.Kalbande, Harsh Panchal,Nisha Swaminathan ,Preeti Ramraj, ”ANFIS Based SPAM Filtering Model for Social Networking Websites”, International Journal of Computer Applications 44(11):32-36, April 2012. Published by Foundation of Computer Science, New York, USA.,DOI : 10.5120/6310-8635 ISSN: 0975 – 8887",
          "Smita Patil, Yogita Mane, Kanchan Dabre, Puneet Devan, D.Kalbande,”An efficient Recommender System Using Collaborative Filtering Method with K-Means Approach” , International Journal of Engineering Research and Applications (IJERA) ISSN: 2248-9622,Mar’12, Page no. 30-35.Impact Factor: 5.011",
          "Priyanka Kulkarni, D.R.Kalbande, Neelam Gulrajani, Srinath Warrior “Smart Card based Android Application for Public Transport Ticketing System”, International Journal of Computer Applications 60(11):29-32, December 2012. Published by Foundation of Computer Science, New York, USA,DOI: 10.5120/9738-4289. ISSN:0975-8887",
          "Mahendra Mehra,Kailas Devadkar, Dhananjay.R.Kalbande, ”Mobile Cloud based Compiler : A Novel Framework For Academia”, International Journal of Advancements in Research & Technology,Volume 2,Issue4,April 2013 Page 445 ISSN 2278 7763.",
          "Nilakshi Jain , Dr. Dhananjay R Kalbande, “Physical and Cyber Crime Using Digital Forensic Approach : A Complete Digital Forensic Tool”, accepted in Springer Book “Advances in Computing and Management” 2016 proceedings .",
          "Nilakshi Jain , Dr. Dhananjay R Kalbande ,”A Comparative Study Based Digital Forensic Tool A Complete Automated Tool”, IJoFCS-International Journal of Forensic Computer Science ,Vol-09, Issue-01, pp 15-22 , October-2014 ISSN: 1980-7333.",
          "Nilakshi Jain , Dr. Dhananjay R Kalbande ,Digital Forensic Models: A Complete Literature Review Published paper in IJSRD-International Journal of Scientific Research & Development Vol-01, Iss-01, Mar-2013 ISSN: 2320-7477.",
          "Nilakshi Jain , Dr. Dhananjay R Kalbande ,Comparative Digital Forensic Model ,Published paper International Journal of Innovative Research in Science, Engineering and Technology Vol. 2, Issue 8, August 2013 ISSN: 2319-8753.",
          "D.R.Kalbande,Surendra Rathod, “ Software development for Course and Program Outcome attainment” presented and published in Journal of Engineering Education Transformations, Volume, No, Month 2015, ISSN 2349-2473, eISSN 2394-1707, at College of Engineering ,Pune. ,Jan 2016, DOI: 10.16920/jeet/2016/v0i0/85634 .",
          "Surendra Rathod,D.R.Kalbande “Improving Laboratory Experiences in Engineering Education”, presented and published in Journal of Engineering Education Transformations, Volume, No, Month 2015, ISSN 2349-2473, eISSN 2394-1707, at College of Engineering ,Pune. ,Jan 2016, DOI: 10.16920/jeet/2016/v29i4/90712",
          "Mrs. Aarti M. Karande, Dr. D.R. Kalbande,” Soft Computing Approach for Measuring Business Process Agility in an Agile Environment”, International Journal of Computer Applications (0975 – 8887), Foundation of Computer Science (FCS), NY, USA ,.June 17. DOI: 10.5120/ijca2017914534",
          "Aarti Karande, D.R.Kalbande ,” Selection of Enterprize Architecture based on agile parameters for SCM-based Case Study” , published in Scholedge International Journal of Business policy & Governance ,ISSN: 2394-3351, Vol04, Issue 05(2017) Pg.36-55.DOI : 10.19085/journal.sijbpg040501",
          "Aarti Karande, D.R.Kalbande ,” Agile Parameter Affecting Supply Chain Mangement Strategy”, published in Int.Journal of Supply Chain Mgmt., Vol.6, No.4, Dec 2017. ISSN : 2050-7399.",
          "Swati Kulkarni, Dhananjay Kalbande, Surekha Dholay and Avinash Sharma,” Cost Optimized Hybrid System in Digital Advertising Using Machine Learning,” Journal of Computational and Theoretical Nanoscience(JCTN)-Apr.2019.",
          "Deepak Kapgate, Dhananjay Kalbande, Urmila Sharwankar, An optimized facial stimuli paradigmfor hybrid SSVEP+P300 brain computer interface” Journal of Neurosurgical Sciences, Publisher – EDIZIONI MINERVA MEDICA, July 2019. DOI : 10.23736/S0390-5616.19.04755-6, ISSN : 1827-1855, Science Citation Index Expanded (SCIE) & SCOPUS.",
          "Deepak Kapgate, Dhananjay Kalbande, Urmila Sharwankar , “Adaptive classification to reduce non-stationarity in visual evoked potential brain-computer interfaces” , Bio-Algorithms and Med-Systems Journal, July 2019 , Publisher – WALTER DE GRUYTER, DOI : 10.1515/bams-2019-0020, ISSN: 1896-530X, ESCI (Thomson & Reuter)& SCOPUS.",
          "Dr.Anjali Yeole Dhananjay Kalbande ,” Security and privacy issues in IoT devices and sensors networks:Machine learning to the rescue” , at Springer book titled “Internet of Things, Smart Computing and Technology :A road map ahead” from ‘Studies in System, Decision , and control’, Springer Book Series (Scopus Indexed”, Abstract accepted for Jan 2020 book series.",
          "Surekha Arjapure, Dhananjay Kalbande,” Pothole detection using Deep Learning classifiers”, at International Journal of Recent Technology and Engineering (IJRTE),Scopus Indexed ,ISSN: 2277-38378, Vol-8, March 2020.DOI: 10.35940/ijrte.F7349.038620",
          "Dhananjay Kalbande; Rohit Naik; Janvi Jatakia; Uday Khopkar,” An artificial intelligence approach for the recognition of early stages of eczema” , at International Journal of Medical Engineering and Informatics, Inderscience Publisher, 2020 Vol.12 No.1, pp.52 – 61, DOI: 10.1504/IJMEI.2020.105656.",
          "Vedant Kumar, Siddhant Kumar, Parth Shingala, Pradhuman Singh, Dhananjay Kalbande,” AI Powered Holistic Solution for Travelers During Pandemic”, at International Journal of Engineering and Advanced Technology (IJEAT), (Scopus Indexed), Volume-9 Issue-6, August 2020, DOI: 10.35940/ijeat.F1428.089620.",
          "Vrushali Joshi, Sarah Hawa, Shriya Akella, Shrishti Kaushik, Dhananjay Kalbande,” Analysis of Therapy Transcripts using Natural Language Processing,” at International Journal of Engineering and Advanced Technology (IJEAT),(Scopus Indexed), Volume-9 Issue-6, August 2020.",
          "Garima Kaushik, Shaney Mantri, Shrishti Kaushik, Dr Dhananjay Kalbande, Dr B. N. Chaudhari. “ANALYSIS OF EFFECTIVENESS OF QUARANTINE MEASURES IN CONTROLLING COVID-19.” Journal of Critical Reviews 7.13 (Aug. 2020), 1655-1665. Print. doi:10.31838/jcr.07.16.261., http://www.jcreview.com/index.php?mno=98957., Also available at : medRxiv preprint doi: https://doi.org/10.1101/2020.04.21.20074245",
        ],
        "National Conference": [
          "MIS for Knowledge Management in the organizationat Bannari Amman Inst.of Technology, 2005.",
          "Network Intrusion and detection Mechanismin the National Conference at Govt.College Engg., Abad, 2005.",
          "A Novel Idea to speed the customer business in the context of Indian railwaysat K.J.Somaiya College, Mumbai, 2006.",
          "Systolic Arrays and Recursion : A new approach for matrix multiplicationaccepted for publication in National Conference on Recent Advances in Computer Vision and Information Technology, Government College of Technology, Coimbatore, March 2010.",
        ],
        "International Conference": [
          "Identifying the research areas in the realm of Information technology in Manufacturing at JMI University, Delhi in Jan 2006.",
          "Neuro-Analytical Hierarchy Process (NAHP)Approach for Selecting ERP Technologies in a Ongoing Project Settings Presented and Published in proceedings of 2010 3rd IEEE International Conference on Computer Science and Information Technology,Chengdu,CHINA",
          "Lip Reading Using Neural Networks, Published in IEEE2010 International Conference on Graphic and Image Processing (ICGIP December 2010) Manila, Philippines",
          "Energy Conservation using Face Detection, Published in IEEE 2010 International Conference on Graphic and Image Processing (ICGIP December 2010) Manila, Philippines.",
          "Total Productive Maintenance Accelerating OEE in a Manufacturing Industry leveraging pervasive technologies. Published in the Proceedings of IEEEInternational conference on Manufacturing Science & Technology (ICMST 2010), Kuala Lumpur, Malaysia 26- 28 Nov 2010.",
          "Zip it Up SMS: A Path breaking Technology Model in the field of Mobile Messaging Presented and Published in proceedings of 2010 3rd IEEE International Conference on Computer Science and Information Technology,Chengdu,CHINA",
          "Neural Network Based Empirical Model Development for E-Business (Mobile) Application, Presented and published in the Proceedings of International Conference & workshop on Emerging Trends and Technology(ICWET -ACM MUMBAI 25-26 Feb 2011) ,ACM International Digital Library, SIGART, USA.ISBN: ISBN:978-1-4503-0449-8,DOI:10.1145/1980022.1980353",
          "Algorithm to access Office Files on Mobile Phones, Presented and published in the Proceedings of International Conference & workshop on Emerging Trends and Technology(ICWET -ACM MUMBAI 25-26 Feb 2011) , and ACM ,New York NY(USA), ISBN :978-1-4503-0449,DOI :10.1145/1980022.1980112",
          "Neuro Analytical Hierarchy Process (NAHP) Approach for CAD/CAM/CIM Tool Selection in the Context of Small Manufacturing Industries,presented and published in IEEE-2012, International Conference ,ICCICT-2012,Mumbai,India. Print ISBN: 978-1-4577- 2077-2 DOI : 10.1109/ICCICT.2012.6398139.",
          "Satellite Positioning Simulation System,Presented and published in IEEE-2012, International Conference ,ICCICT- 2012,Mumbai,India.DOI: 10.1109/ICCICT.2012.6398141",
          "Demonstration of Signature Analysis using Intelligent Systems published and presented at 3rd International Conference on advances in Computing, communication and control , with Springer 18-19 Jan 2013",
          "Emotion Detection from “The SMS of the Internet” Accepted for presentation in IEEE International Conference Dec 19-21 2013,,IEEE RAICS-2013,Trivandrum,Kerala,India",
          "Voice enabled Android application for vehicular complaint system: Using GPS and GSM-SMS technology,Published in IEEE sponsored International Conf. on Information and Communication Technologies (WICT), Trivendrum Oct 30, 2012 DOI: 10.1109/WICT.2012.6409133 Publication Year: 2012 , Page(s): 520 – 524",
          "Machine Learning Applied To Human Learning presented in IEEE Conference IEEE INDICON 2013,13-15 Jan 2013,IIT Bombay,Mumbai,India",
          "Gesture detection system using smart watch based motion sensors Authors :Sumit G.,O.Karande,A.Pandya,Dr.D.R.Kalbande Presented and published in IEEE Conference (CSCITA –Approved by IEEE), April 4-5 2014,Mumbai.",
          "Research on Educative Games for Autistic Children Authors: Sridari Iyer,Dr.D.R.Kalbande Presented and published in IEEE Conference (CSCITA –Approved by IEEE), April 4-5 2014,Mumbai.",
          "Review of Mobile Data Offloading through Wi-Fi “Authors: Deepshikha H.,Dr.D.R.Kalbande Presented and published in IEEE Conference (CSCITA –Approved by IEE), April 4-5 2014,Mumbai",
          "“Web service selection based on QoS using Feedforward Neural Network”. Authors: Aarti Karande,Dr.D.R.Kalbande Published in International Conference ICICT 2014,IEEE Delhi Section,in KIET,Gaziabad",
          "Nilakshi Jain , Dr. Dhananjay R Kalbande ,” Computer Forensic Tool using History and Feedback Approach”, International Conference on Reliability, InfoCom Technologies and Optimizations IEEE Conference ,Delhi (ICRITO-2015). (Print ISBN: 978-1-4673-7231-2).",
          "Nilakshi Jain , Shwetambari Pawar, Dr. Dhananjay R Kalbande , ”Network Security Analyzer: Detection and Prevention of Web Attacks Using Digital Forensic” , International Conference on ICT for Intelligent Systems (ICTIS – 2015)Ahmadabad. (Springer SIST), Series Title: Smart Innovation, Systems and Technologies (SIST) ISSN Number – 2190-3018.",
          "Nilakshi Jain , Dr. Dhananjay R Kalbande , ”Physical and Cyber Crime Using Digital Forensic Approach”,International Conference on ICT for Intelligent Systems (ICTIS – 2015) Ahmadabad(Springer SIST), Series Title: Smart Innovation, Systems and Technologies (SIST) ISSN Number – 2190-3018.",
          "Seema Kolkur ,Dhananjay Kalbande,”Review of Machine Learning Algorithms in R software for Diagnosis of ESD Diseases” , presented in ACM Women in Research Conference (ACM-WIR 2016),at Sri Aurobindo Institute of Technology,Indore.21st March 2016.",
          "Anjali Yeole, Dhananjay Kalbande,” Use of Internet of Things (IoT) in Healthcare: A Survey “, accepted for presentation and publication in ACM Women in Research Conference (ACM-WIR 2016),at Sri Aurobindo Institute of Technology,Indore.21st March 2016.",
          "Nilakshi Jain, Dhananjay Kalbande ,” Empirical relationship between Victim’s occupation and their knowledge of Digital Forensic ”, presented and published in ACM Women in Research Conference (ACM-WIR 2016),at Sri Aurobindo Institute of Technology,Indore.21st March 2016.",
          "Nilakshi Jain ,Dhananjay Kalbande, “Computerized Forensic Approach Using Data Mining Techniques”, accepted presented and published in ACM Women in Research Conference (ACM-WIR 2016),at Sri Aurobindo Institute of Technology,Indore.21st March 2016.",
          "Asha Bharambe,Dhananjay Kalbande, “Techniques and Approaches for Disease Outbreak Prediction: A Survey”, presented and published in ACM Women in Research Conference (ACM-WIR 2016),at Sri Aurobindo Institute of Technology,Indore.21st March 2016.",
          "Ankit Srivastava ,Dhananjay Kalbande,” Neuro-Bi Approach For Management Information System For An Enterprise”, presented in 2016 International conference on Smart structure and system( 2016 IEEE ICSSS),at Saveetha Engineeing College,Anna University,Chennai, India, March 23,24 .2016.",
          "Nikita Patil, Dhananjay Kalbande, “Demonstrating Protein Analysis for cancer Disease using Approximation Algorithms” presented ,3rd International conference on Electronics and communication system,ICECS.25-26 Feb.2016. Karpagam College of Engineering,Coimbatore,India.",
          "Devashree Mehta, Faizaan Charania, Dhananjay Kalbande ,” Comparative Study of KNN and ENN approaches for the Netflix Challenge” presented and published in 2016 International Conference on Engineering and Technology (ICET). (IEEE Sponsored) 16 Dec – 17 Dec 2016.",
          "Suvarnamala Sonune,Dhananjay Kalbande, “Issues in IoT Healthcare Platforms: A Critical Study and Review”, presented and published in 2016 International Conference on Engineering and Technology (ICET). (IEEE Sponsored) 16 Dec – 17 Dec 2016.",
          "Dr.Mithali Jage, Dr.Uday Khopkar, Dr.Dhananjay Kalbande, Maitri Shroff, Sanskruti Shah, Shivani Inamdar ,Janvi Jatakia, Rohit Naik “ Artificial Intelligence enabled Machine Learning approach for recognizing early stage of Psoriasis and Eczema” presented in 10th ADC 2016, Asian Dermatological Congress, Oct.2016,Mumbai,India. The work presented in this Congress is a research outcome of collaborative research work with Skin and VD department ,Seth G.S.Medical College and KEM Hospital and SPIT,Mumbai.",
          "Suvarnamala Sonune,Dhananjay Kalbande, “ IoT Enabled API for Secure Transfer of Medical Data”, Presented in IEEE sponsored 2017 International Conference on Intelligent Computing and control(I2C2-17) , at KCEEm Coimbatore , India. 23-24 June 2017.",
          "Sridari Iyer,Dr.D.R.Kalbande, “Assess Autism level while playing Games “, presented in 2nd International conference on Communication system, computing and IT applications, IEEE-CSCITA , 7-8 April 2017,Mumbai., DOI: 10.1109/CSCITA.2017.8066573, ISBN: 978-1-5090-4381-1",
          "Ayush Shah, Akash Dubey, Vishesh Hemnani, Divye Gala, D.R.Kalbande, “Smart Farming System : Crop Yield Prediction Using Regression Techniques” ,presented at International conference on Wireless communication (ICWiCOM-2017), 19-20 Jan 2018, in DJSCOE, Mumbai, India.( Paper to be published in Springer Digital Library under the series “Lecture notes on Data Engineering and Communication Technologies”.)",
          "Anjai Yeole, D.R.Kalbande, “MAC based Group Management Protocol for IoT[MAC- GMP-IoT ]”, presented at International conference on Wireless communication (ICWiCOM-2017), 19-20 Jan 2018, in DJSCOE, Mumbai, India.( Paper to be published in Springer Digital Library under the series “Lecture notes on Data Engineering and Communication Technologies”.",
          "D.R.Kalbande, Ms.Anushka Kanawade, Ms. Smruti Varvadekar ,” Comparative analysis of methods for monitoring activities of daily living for the elderly people”, presented at International conference on Wireless communication (ICWiCOM-2017), 19-20 Jan 2018, in DJSCOE, Mumbai, India.( Paper to be published in Springer Digital Library under the series “Lecture notes on Data Engineering and Communication Technologies”.",
          "Ms. Pratiti Desai, Ms. Anushka Kanawade, Ms. Smruti Varvadekar, D.R.Kalbande, “ Gesture and Voice Recognition in Story Telling Application” presented in International Conference on Smart City and Emerging Technologies (ICSCET 2018) in association with IEEE, in UCE, 5th Jan.,2018, Mumbai, India.",
          "Aditya Das, Shantanu Gawde ,Khyati Suratwala, D.R.Kalbande, “Sign Language Recognition using Deep Learning on custom processed static gesture images” , presented in International Conference on Smart City and Emerging Technologies (ICSCET 2018) in association with IEEE, in UCE, 5th Jan.,2018, Mumbai, India.",
          "Sanket Agrawal, Rucha Rangnekar, Divye Gala, Sheryl Paul , D.R.Kalbande,” Detection of Breast Cancer from Mammograms using a Hybrid Approach of Deep Learning and Linear Classification”, presented in International Conference on Smart City and Emerging Technologies (ICSCET 2018) in association with IEEE, in UCE, 5th Jan.,2018, Mumbai, India.",
          "Anjali Yeole , D.R.Kalbande ,” Change Management Approach for integrating IoT Technology in Healthcare System”, presented in International Conference on Smart City and Emerging Technologies (ICSCET 2018) in association with IEEE, in UCE, 5th Jan.,2018, Mumbai, India.",
          "John Britto, D.R.Kalbande ,” Analysis of Technological Advances in Autism”, presented in International Conference on Incentive Computing and Informatics (ICICI 2017) ,sponsored by IEEE, in REC ,23-24 Nov.2017,Coimbtore ,TN, India.",
          "Asha Bharambe, D.R.Kalbande ,”Dengue Outbreak Prediction using Data Mining Techniques” , presented in International Conference on Electrical, Electronics, Computers, Communication,Mechanical and Computing (EECCMC) on 28th & 29th January 2018 at Priyadarshini Engineering College, Chettiyappanur, Vaniyambadi – 635751, Vellore District, Tamil Nadu, India.",
          "Twinkle Rambhia, Madhuri Dhodi ,Vaibhavi Patel, D.R.Kalbande ,” Design of an Intelligent system for Autism “, presented in 2018 International conference on Communication, Information, & Computing Technology (ICCICT), Feb 2-3 2018, Mumbai,India.",
          "Aarti M. Karande , Dr. Dhananjay R. Kalbande, Dr. Avinash Sharma ,” SCM Enterprise solution using Soft Computing Techniques”, presented in 3rd International conference on Soft Computing: Theories and application(SoCTA 2018) at Dr. B.R.Ambedkar N.I.T., Jalandhar, Punjab on 21st Dec to 23rd Dec.2018.",
          "Surekha Arjapure, Kalbande D.R.,” Review on Analysis Techniques for Road Pothole Detection” , presented in 3rd International conference on Soft Computing: Theories and application(SoCTA 2018) at Dr. B.R.Ambedkar N.I.T., Jalandhar, Punjab on 21st Dec to 23rd Dec.2018.",
          "D.R. Kalbande, Dr.Uday Khopkar, Dr.Avinash Sharma, Neil Daftary,,Yash Kokate, and Royston Dmello,” Early stage detection of Psoriasis using Artificial Intelligence and Image Processing” , presented in 3rd International conference on Soft Computing: Theories and application(SoCTA 2018) at Dr. B.R.Ambedkar N.I.T., Jalandhar, Punjab on 21st Dec to 23rd Dec.2018.",
          "Anjali Yeole, D.R.Kalbande,Avinash Sharma, “Security of 6LoWPAN IoT Networks in Hospitals for Medical Data Exchange”, presented in International Conference on Pervasive Computing Advances and Applications – Jan 8-10,2019 PerCAA 2019(Elsevier).",
          "Sai Yerramreddy, Samriddha Basu, Ananya Dharmendra Ojha and Dhananjay Kalbande,” Machine Learning approach for diagnosis of Autism spectrum disorder”,at International conferecne on Innovative computing and communication(ICICC2019),presented at Pre-Conference Symposium of ICICC-2019(Springer Sponsored) , at Guru Nanak Institute of Management, New Delhi, India on 16th March 2019. (Best Paper Award)",
          "Madhura Gore, Ananya Dharmendra Ojha, Ananya Navelkar and Dhananjay Kalbande,“Methodologies for Epilepsy Detection: Survey and Review” , at Internationalconferecne on Innovative computing and communication(ICICC2019),presented at Pre-Conference Symposium of ICICC-2019(Springer Sponsored) , at Guru Nanak Institute ofManagement, New Delhi, India on 16th March 2019.",
          "Dhananjay Kalbande, Rithvika Iyer, Tejas Chheda and Avinash Sharma,” Transfer Learning Model for detecting early stage of Prurigo Nodularis, presented at Pre- Conference Symposium of ICICC-2019(Springer Sponsored) , at Guru Nanak Institute of Management, New Delhi, India on 16th March 2019.",
          "Deepak Kapgate and Dhananjay Kalbande,” Optimization of External Stimulus Features for Hybrid Visual Brain Computer Interface,” presented at Pre- Conference Symposium of ICICC-2019(Springer Sponsored) , at Guru Nanak Institute of Management, New Delhi, India on 16th March 2019.",
          "Swati Kulkarni, Dhananjay Kalbande ,”Video-based Human Action Recognition using Multiclass Open set Classification”, presented at International Conference on Emergent Research in Mathematics and Engineering (ICERME-2019) at the Department of Mathematics, NIT Agartala., 17th -18th May, 2019.",
          "Aarti Karande, Prachi Dalvi and Dhananjay Kalbande,” Emotion Identification using CNN based Transfer Learning”, accepted for presentation at Second International Conference on Advance Computing and Application (ICACTA 2020) on the theme “Intelligent Computing”.DJICACTA2020",
          "Renuka Pawar, D.R.Kalbande , “Elliptical Curve Cryptography Based Access Control Solution For Iot Based WSN” in International Conference on Innovative Data Communication Technologies and Application (ICIDCA 2019) , Oct 17-18 2019 ,Coimbatore,India",
          "Jignesh Sisodia, D.R.Kalbande, ” “Machine Learning: An aid in detection of Neurodegenerative disease Parkinson”. International Conference on Innovative Data Communication Technologies and Application (ICIDCA 2019) , Oct 17-18 2019 ,Coimbatore,India.",
          "Surabhi Ghankutkar, Neelabh Sarkar, Pooja Gajbhiye, Sanyukta Yadav, Dr.Dhananjay Kalbande , Prof.Nida Bakereywala,” Modelling Machine Learning For Analysing Crime”, presented at IEEE International Conference on Advances in Computing, Communication and Control (ICAC3 19) , at Fr.CRCE,Bandra, MumbaiDecember 20 – 21, 2019.",
          "Ira Durve, Sairandree Patil, Supriya Ghuge, Dhananjay Kalbande, “Machine Learning approach for Physiotherapy Assessment “,presented at IEEE International Conference on Advances in Computing, Communication and Control (ICAC3 19) , at Fr.CRCE,Bandra, MumbaiDecember 20 – 21, 2019.",
          "Rebecca Dsouza, Shubham Sahu, Ragini Patil,Dhananjay Kalbande ,” Chat with Bots Intelligently:A Critical Review & Analysis “, presented at IEEE International Conference on Advances in Computing, Communication and Control (ICAC3 19) , at Fr.CRCE,Bandra, MumbaiDecember ,20 – 21, 2019.",
          "Mahendra Mehra, D. R. Kalbande, Shubham Mankar, Sohaa Mutsaddi, “Data mining in educational systems for effective student mentoring”, presented in IEEE 2019 International Conference on Advances in Computing, Communication and Control (ICAC3) 20-21 Dec 2019 DOI: 10.1109/ICAC347590.2019.9036803",
          "Ghorai Arnab , Gawde Sarah and Kalbande Dhananjay, “Digital Solution for Enforcing Social Distancing “ (May 31, 2020). at Proceedings of the International Conference on Innovative Computing & Communications (ICICC) 2020, Available at SSRN: https://ssrn.com/abstract=3614898 or http://dx.doi.org/10.2139/ssrn.3614898",
          "Garima Kaushik, Shaney Mantri, Shrishti Kaushik, Dr Dhananjay Kalbande, Dr.B.N. Chaudhari,” Analysis of Effectiveness of Quarantine Measures in Controlling COVID-19”, presented at International Conference on Recent Development in Engineering, Science, Humanities and Management (ESHM-2020), 25th July,2020,Goa, India.",
          "Rushabh Khara, Deepika Pomendkar, Rishika Gupta, Ishwarlal Hingorani and Dhananjay Kalbande, “Micro Loans for Farmers”, presented at 11th ICCCNT 2020, International conference on Computing, communication and networking technologies, July1-3,2020, IIT Kharagpur, West Bengal, India in association with IEEE Kharagpur Section.",
          "Priyam Das, Dhananjay Kalbande, “Behavioural Analysis of Multi-Source Social Network Data Using Object-Centric Behavioural Constraints and Data Mining Technique”, presented at 11th ICCCNT 2020, International conference on Computing, communication and networking technologies, July1-3,2020, IIT Kharagpur, West Bengal, India in association with IEEE Kharagpur Section.",
          "Ajani Yameen, Mangalorkar Krish, Nadar Yohann, Mehra Mahendra, Kalbande Dhananjay, “ College Project Preservation and Emulation using Containerization over Private cloud”, presented in 5th International Conference on Information and Communication Technology for Competitve Strategies Dec 11-12 2020, (ICTCS-2020)",
          "Mohit Sanjay Badve, Vinal Bagaria, Apoorva Chaudhari, Palak Davda, Dhananjay Kalbande,” Garbage Collection System Using IoT for Smart City”, presented in 4th International conference on I-SMAC (IoT in Social, Mobile, Analytics and Cloud) (I-SMAC 2020) 7th Oct 2020. DOI: 10.1109/I-SMAC49090.2020.9243387",
          "Manan Doshi, Jimil Shah, Aumkar Gadekar, Anish Dixit, Shloka Shah, Parth Jardosh, Dhananjay Kalbande “MeghNA: Cloud Detection and Motion Prediction in INSAT Images” presented in ICACCP 2021 , 3rd International conf. om Advanced Computational and communication Paradigm, ,SMIT, Sikkim,Mar 22-24 2021, Springer AISC series.",
          "Shwetambari Borade, Dhananjay Kalbande,” Survey paper based critical reviews for Cosmetic Skin Diseases”, presented in IEEE sponsored International conf. On Artificial Intelligence and smart systems (ICAIS) Mar 2021. DOI: 10.1109/ICAIS50930.2021.9395803",
          "Sahai Vedant, D’Costa Jason, Srivastava Mayank, Mehra Mahendra, Kalbande Dhananjay, ” Leveraging Deep Learning and IoT for monitoring COVID19 Safety Guidelines within College Campus” presented in 10th International Conference, IACC 2020, Panaji, Goa, India, December 5–6, 2020,published in Book seriea in Avvanced computing, Springer, ISBN: 978-981-16-0401-0",
          "Tejas Chheda, Soumya Kopakka, Rithvika Iyer ,Dhananjay Kalbande “A Survey on Object Detection, Annotation and Anomaly Detection Methods for Endoscopic Videos” ,presented in 14-16 Oct 2020 5th IEEE International Conference on Computing, Communication and Security (ICCCS) Oct 2020. DOI: 10.1109/ICCCS49678.2020.9277436",
          "Tejas Chheda, Soumya Kopakka, Rithvika Iyer ,Dhananjay Kalbande ,” Gastrointestinal Tract Anomaly Detection from Endoscopic Videos Using Object Detection Approach” presented in International Symposium on Visual Computing, In: Bebis G. et al. (eds) Advances in Visual Computing. ISVC 2020. Lecture Notes in Computer Science, vol 12510. Springer, Cham. https://doi.org/10.1007/978-3-030-64559-5_39. Dec 2020.",
          "Anagha Pasalkar, Dhananjay Kalbande, „Prediction and Analysis of Recurrent Depression Disorder: Deep Learning Approach”, presented in 2nd Congress on Intelligent Systems (CIS 2021) organized by Soft Computing Research Society (SCRS Book Series) and CHRIST (Deemed to be University), Bengaluru , India ,during September 04-05, 2021.",
          "Dhananjay Kalbande, Pulin Prabhu,Anisha Gharat, Tania Rajabally,” A Fraud detection system using Machine Learning”, presented in the 12th IEEE international conference on computing, communication and networking technologies (ICCCNT), IIT Kharagpur,West Bengal, India, during 6th & 8th July, 2021.",
        ],
        "Research Grant Projects": [
          "Research Grant from National Science & Technology Entrepreneurship Development Board (NSTEDB), Department of Science & Technology (DST), Govt. of India, New Delhi for the project titled ZIUS.",
          "Worked as Principal Investigator @ University of Mumbai Minor-Research Grant for the year 2010-2011 for the project titled STACsys: SMS based Taxi and Auto Rickshaw Complaint System. Status:Project completed and report is submitted to Mumbai University.",
          "Worked as a co-investigator @ University of Mumbai Minor-Research Grant for the year 2011-2012 for the project titled BMX : Bluetooth Mouse controller for LCD projector and desktop PC: Status:Project completed and report is submitted to Mumbai University.",
          "Worked as a Principal Investigator for University of Mumbai Minor-Research Grant for the year 2013-2014 for the project titled “Sign Language Translator for an Android phone”. Status:Project completed and report is submitted to Mumbai University.",
        ],
        "Courses/Workshop/Seminar Conducted": [
          "Conducted one month training program on Office Automation in Bharati Vidyapeeth Institute of Technology, Navi Mumbai",
          "Conducted two days short term course on Data Mining & Warehousing in Sardar Patel college of Engineering, Mumbai.",
          "Coordinated & conducted the two-week Application development certificate programme (STTP) on VB.Net & ADO.Net from 22nd June to 4th July.209.",
          "Coordinated & conducted the 8-days Application development certificate programme (STTP) on VB.Net & ADO.Net from 22nd Aug to 1st Sept.2009.",
          "Coordinated 1-day workshop on software testing on 12th march 2010",
          "Coordinated & Conducted the 2-days Application development certificate programme (STTP) on VB.Net & ADO.Net from 6th to 7th march 2010.",
          "Conducted a session on PC Assembly organized by CSI S.P.I.T. Chapter on 14th March 2010",
          "Coordinated and conducted the 2-days workshop on Neural Network & Fuzzy Logic (NNFL) from 27th to 28th March 2010. Coordinated and conducted one week STTP on VB. Net & ADO .Net from 7th to 15th Aug 2010.",
          "Conducted a session on Network Simulator 2.0 at ISTE approved workshop in THAKUR ENGG.COLLEGE, Kandivali,Mumbai.",
          "Conducted seminar on Designing Neural Network using Java Object Oriented Neural Engine (JOONE) and Neural Easy Tool for Pattern Recognition on 24th February 2010 under the national state level seminar on Artificial Intelligence and its Application organized by Modern College of Engineering with University of Pune under QIP.",
          "Coordinated & Conducted the 4-days Application development certificate programme (STTP) on VB.Net & ADO.Net from 7th Aug to 11th Aug 2012",
          "Coordinated 3-days Application development certificate programme (STTP) on Ruby on Rails from 15th Sept to 17th Sept 2012. Conducted and trainer for the two-days workshop on Neural network & fuzzy logic from 20th & 21st March.2009.",
          "Conducted Workshop on Network Simulator at Thakur College of Engineering And Technology, Mumbai.",
          "Coordinated & conducted 2-days workshop on Human Computer Interaction from 16th and 17th Feb. 2013.",
          "Coordinated and conducted the 2-days workshop on Soft Computing and Neuro Morphic Engineering from 7th and 8th Feb. 2014.",
          "Coordinated and conducted the 2-days workshop on Soft Computing ( Neural Network & Fuzzy Logic) & its practices on 10th and 11th July 2015.",
          "Conducted two days session in one week ISTE approved STTP on Outcome Based Education : Accreditation and Quality Assurance from 17-21 Nov 2014 at Fr.CRCE,Bandra,Mumbai.",
          "Conducted one day workshop on Outcome Based Accreditation and Acade`mic Learning Process on 16th Jan 2015.",
          "Coordinated and conducted the 2-days workshop on Soft Computing and its practices from 10th and 11th July. 2015.",
          "Coordinated and conducted the 5-days workshop on Emerging technologies from 22nd Dec 2016 to 27th Dec. 2016.",
          "Conducted Tutorial on Transfer Learning at Pre- Conference Symposium of ICICC-2019(Springer Sponsored) , at Guru Nanak Institute of Management, New Delhi, India on 16th March 2019.",
          "Conducted webinar on “AI in Health Sector” on 9th June 2020 at Shah and Anchor Kutchhi Engineering College, Mumbai, India.",
        ],
        "Industrial Training/Workshop": [
          "Delivered hands-on on Net. Simulator in ISTE approved workshop at Thakur Engg. college.",
          "Delivered Seminar on “Designing Neural Network using JooNE “at Modern Engg.Coll, with Univ. Of pune, under QIP grant.",
          "Attended 3 days International Conf. on Operation Management organized by SOMS, at NITIE from 17-19 Dec.2010",
          "Delivered Expert Talk on Research Proposal Preparation and current research trends in 2-days workshop on “Research Proposal and Technical Paper writing” organized by S.P.I.T., 10,11 April 2015.",
          "Delivered a talk on Research Proposal Writing and Current Research Trends in One Day National Workshop on “Trends in Big Data and Hadoop” organized by Pillai HOC COET,Rasayani on 11th April 2015.",
          "Delivered Expert Talk on Research Proposal Preparation and current research trends in 2-days workshop on “Research Proposal and Technical Paper writing” organized by S.P.I.T., 8th and 9th April 2016.",
          "Delivered Expert Talk on “CO-PO Attainment” in ISTE Approved One week STTP on Outcome Based Education – Innovative Teaching Learning Practices and Evaluation 2nd May to 06th May, 2016, SPIT, Mumbai.",
          "Delivered a talk on “Articulation of CO” in AICTE-ISTE Induction/Refresher Programme on 3rd May to 9th May 2018.",
          "Attended two days workshop on AI/ML by IEEE Bombay Section, at NITIE 10th and 11 th Aug.2018.",
          "Coordinated Seminar on Shaping a Data science curriculum: Unleashing Potential of Big Data ,IoT, Machine Learning and AI by SAS Institute and SPIT on 27/11/18 to 29/11/2018.",
          "Attended 4-days Online Course on Examination Reforms conducted during 22-25 April, 2020 organized by AICTE.",
          "Attended 3-days Digital Symposium on AI for India from 26th Aug to 28th Aug.2020 by JIO, AI Division India.",
        ],
        Other: [
          "Worked as an Expert to evaluate the Result Processing Software for MSBTE, Mumbai, India.",
          "Worked as a Technical Expert in the Scrutiny Work assigned by the AICTE, New Delhi, India.",
          "Worked as a Convener and Member of Syllabus Revision Committee of Mumbai University.",
          "Worked as a Project Incharge for consultancy work received from Lunchex Online Pvt.Ltd and successfully delivered the product to Lunchex Online Pvt.Ltd in June 2014.",
          "Develop the Industry Institute Interaction Cell (IIC-Cell) in Department of Computer Engineering to provide the Internship and motivate the students for undertaking Industry oriented projects.Currently working a Chair of IIC-Cell in S.P.I.T.",
          "Working as a Chair of Entrepreneurship Cell(E-Cell) of S.P.I.T.",
          "Working as a Member (Advisory Board), of IEDC,S.P.I.T.",
          "Currently Working as a Member Adhoc Board of Studies Computer Engineering, University of Mumbai, Mumbai.",
          "Currently Working as a Chairman-Ad-hoc Board of Studies in Computer Application.(M.C.A.),University of Mumbai,Mumbai.from 5th July 2016",
          "Worked as Member, Scrutiny Committee, Affiliation Section, University of Mumbai.",
          "Worked as Co-Coordinator(Mumbai Sub-urban), AVISHKAR,University of Mumbai.",
          "Worked as Advisory Board: ICICC-2019 Springer Conference, New Delhi.",
          "Currently working a s Member, RRC Committe ,Univerisity of Mumbai.",
          "Worked As mentor in Maharashtra State Skill Development Society (MSSDS) in association with National Skill Development Corporation (NSDC) for state level skill competition for level 3 at L&T STA Madh, Oct 2021.",
        ],
      },
    },
    {
      id: "dr-sujata-s-kulkarni",
      name: "Dr. Sujata S Kulkarni",
      image: "/assets/faculty/dr-sujata-s-kulkarni.png",
      designation: "Associate Professor",
      qualification: "Ph.D (Electronics), RSTMNU Nagpur",
      education: [
        "Ph.D Electronics(RSTMNU Nagpur)",
        "M.E. Electronics Engineering",
        "B.E. Electronics Engineering",
      ],
      contact: [
        "Email: sujata_kulkarni@spit.ac.in, Extension: 386",
        "Department of Computer Science and Engineering,",
        "Sardar Patel Institute of Technology, Andheri(W), Mumbai 400058.",
      ],
      resume: "",
      data: {
        "Area of Interests": [
          "Pattern Recognition",
          "Communication and Networking",
          "Wireless Communication Networks",
          "Embedded System",
        ],
        "Pre PhD papers published at conferences, Journals": [
          "Sujata Kulkarni, Personal Authentication Using Fingerprint, International   Journal of computer   Science   and   Information     Technology   (IJCSIT)   vol.4,   no   2, ISSN   0974-8385,2011.",
          "Sujata   Kulkarni,   Study   of   Multimodal   Soft   Biometric   Authentication   System,International Journal of Computer Science and Application, issue II, ISSN: 0974-6285,2011.",
        ],
        "PhD research papers published at conferences, Journals and Transactions":
          [
            "Sujata   Kulkarni &   Ranjana   Raut,   Wavelet   Based   Finger   Knuckle   and   Finger   VeinAuthentication System, International Journal of Advances in Image and Video processing(UK) 29 July, 2016. (DoI 10.14738/aivp.44.2136).",
            "Sujata Kulkarni,& Ranjana Raut Soft Feature based Personal Recognition, 8th     International conference on soft computing and Pattern Recognition, School of IT & Engineering, VIT Vellore, Nov. 2016, Springer Publication.",
            "Sujata   Kulkarni &   Ranjana   Raut,   Soft   Feature   Based   Personal   Recognition,International Conference on Soft Computing and Pattern Recognition, Dec 2016.",
            "Anush M.& Sujata Kulkarni, Multi- Biometric Authentication System Using Feature Level Fusion, International Journal of Research in Engineering and Technology, vol.5, issue7,2016, pp. 216-223.( DOI 10.15623/ijret)",
            "Sujata Kulkarni, Ranjana Raut, and Pravin .Dakhole, Recent Biometric Trait: Finger Vein   Acquisition   System, Grenze International   Journal   of   Electrical   and   Electronics Engineering, GIJEEE vol. 1, issue1, Nov. 2015.",
            "Sujata   Kulkarni,   Ranjana   Raut,&   P.K.Dakhole,Vein   pattern   for   personal authentication, International Journal of Electronics, Communication & Soft Computing Science and Engineering, 2015.( Journal Impact Factor-2.02 Indexed by- ProQuest, DOAJ and many International Bodies)",
            "Sujata   Kulkarni,   Ranjana Raut &   Pravin   Dakhole, and   Finger Vein   Recognition,Journal of Electrical and Electronics Engineering International Organization of Scientific Research, vol. 4, e-ISSN: 2278-1676, p-ISSN: 2320-3331 PP 32-36, 2014.",
            "Sujata   Kulkarni,   Ranjana   Raut   &   Pravin   Dakhole,   Authentication   using   Correlated Biometrics Traits, International Journal of Computer Science And Applications, vol. 6,no.2, Apr 2013.",
            "Sujata Kulkarni, Ranjana Raut & Pravin Dakhole, Secure Biometrics: Finger Knuckle Print, International   Journal   of Advanced   Research   in   Computer   and   Communication Engineering, vol. 1, issue10, December 2012.",
            "Sujata Kulkarni, Ranjana Raut, & Pravin .Dakhole, Identification System using Finger Knuckle Features, International Journal in Advances in Electronics Engineering, volume2, issue 2 ISSN 2278 – 215X 2012.",
            "Sujata Kulkarni, Personal Authentication Using Fingerprint, International   Journal of computer   Science   and   Information     Technology   (IJCSIT)   vol.4,   no   2, ISSN   0974-8385,2011.",
            "Manjari Peethala & Sujata Kulkarni Secure Biometric Authentication using Steganography, IEEE International WIE Conference on Electrical and Computer Engineering 2016.",
            "Sujata   Kulkarni,   Ranjana   Raut,   &   Pravin   Dakhole Wavelet   Based   Modern   FingerKnuckle   Authentication, Elsevier   Publications Science   Direct   Procedia   Computer Science   (70)   (2015)   pp.   649-657.   (PII: S1877-0509(15)03265-2,   DOI:10.1016/j.procs.2015.10.101)",
            "Sujata Kulkarni, Ranjana Raut, &   Pravin .Dakhole Transform Domain   Finger Vein based Unimodal   Biometric   Recognition   System International Conference on Communication Network (ICCN) ICCN 2015, 978-1-5090-0051-7, pp. 32-36.",
            "Sujata Kulkarni, Ranjana   Raut, &   Pravin .Dakhole,  Recent biometric trait: Fingervein Acquisition System, McGraw-Hill Education Professional Joint Conferences on ACE, AET, ComNet and PCIC 2014.IDES, pp. 236-247. (doi:10.3850/978-981-09-5247- 1_012)",
            "Sujata Kulkarni & Ranjana Raut, Finger Vein Recognition, International conference on Advances in Engineering and Technology (ICAET-2014), pp32-47",
            "Sujata   Kulkarni &   Ranjana   Raut,   Identification   System   using   Finger   Knuckle Features, International Conference on Advances in Computer, Electronic and Electrical Engineering, 2012. (doi:10.3850/978-981-07-1847-3P0971)",
            "Sujata Kulkarni & Ranjana Raut, Authentication using Correlated Biometrics Traits,National Conference on Advances in Computing & Networking(NCAICN), 2013.( IJCSAISSN: 0974-1011).",
          ],
        "Post PhD papers published at conferences, Journals and Transactions": [
          "Darshan Kale & Sujata Kulkarni Integrating Fusion level for Biometric Authentication system, IOSR Journal of 1Electronics and Telecommunication Engineering, vol.12, issue 1, ver.1, pp 65-72, Feb 2017.",
          "Sujata Kulkarni, R.D. Raut, Soft Feature Based Personal Recognition, Advances in Intelligent Systems and Computing series, vol.no.614, Springer, 2017",
        ],
        "Consultancy Projects": [
          "Smart Parking Management system using IoT, Minor research Grant by University of Mumbai",
        ],
        "Work Experience": [
          "EUREKA Engineering Enterprises,Pune13th July 1999 to 30th Nov 2000.",
          "Quality Control Engineer CMS Computers Ltd.,Pune 5th Dec 2000 to 31st May 2005 R&D Engineer.",
          "Atharva College of Engineering , Mumbai 9th June 2005 to30th Sep2007 as a Lecturer Malad(w), Mumbai.",
          "Atharva College of Engineering , Mumbai1st Oct 2007 to 30th May 2008 as a Fifth Pay Assistant Professor in Department of Electronics and Telecommunication, Malad(w), Mumbai.",
          "Thakur College of Engineering and Technology, Mumbai17th July 2008 to 30th June 2015 as a Fifth Pay Assistant Professor ,Kandivali (E), Mumbai.",
          "Thakur College of Engineering and Technology, Mumbai From 1st July 2015 to 31st Dec. 2017 as Associate Professor in Electronics and Telecommunication, Kandivali (E), Mumbai.",
        ],
        Awards: [
          "Best Paper Award: Sujata Kulkarni, Ranjana Raut & Neha Gharat, Biometric Authentication Using Kekre’s Wavelet Transform, Electronic System; Signal processing, and Computer Technologies (ICESC) 2014.978-1-4799-2102-7. (DOI 10.1109/ICESC.2014.22)",
          "Member, Best   Quality   Enhancement   Team-2009 conducted   by   National   Centre   for Quality Management, 2010",
        ],
        "Professional Affiliations": [
          "Recognized UG & PG Teacher of Mumbai University in Electronics& Telecommunication Engineering.",
          "Recognized Ph.D Guide of Mumbai University in Electronics& Telecommunication Engineering.",
          "Advisory Committee member of International Conference on Inventive Research in Computing Applications.",
          "Reviewer for Information Processing Letters.",
          "Reviewer for Journal of King Saud University- Computers and Information Sciences.",
          "Qualified as an Examiner of IMC Ramakrishna Bajaj National Quality Award 2015-16.",
          "Auditor of ISO 9001:2008.",
          "Authored book on Digital Logic Design and Application, Vipul Publication for Electronics and Information Technology, 2005.",
          "Membership of ISTE, IETE, IET",
        ],
        "Expert Lecturers delivered at Short Term Teachers Training Program (ISTE / STTP)":
          [
            "Resource person at TCET for subject orientation of CCTN (T.E.VI EXTC) ,University of Mumbai, 2014",
            "Judge, Technical Paper Presentation of IETE, PATW Programm.",
            "Member, Syllabus Review and Revision Committee, (R 2012) and (R 2016) for UG and PG programme in Electronics and Telecommunication Engineering, University of Mumbai.",
          ],
        "Conferences and Short Term Courses Organized": [
          "Co-Convener, International   Conference   and   Workshop   on   Electronics   and Telecommunication Engineering in association with IET, 2016 & 2017.",
          "Co convener for IET- PATW-(South Asia Level) inter college and inter network technical paper Presentation in association of IET in TCET on 7th march 2015",
          "Member, organizing   committee,   National   Workshop   on   Engineering   Colloquium   at International Conference and workshop on Advanced Computing for Masters of students,TCET, 2013-14 & 2014-15.",
          "Member,   Organizing Committee,   National   Workshop   on   Emerging   Trends   in Communication Engineering, International Conference TCET, 2010-12.",
          "Course Coordinator, STTP on Internet of Thing: Recent Trends and Multidisciplinary Application in association with IET, June 2016.",
        ],
      },
    },
    {
      id: "prof-varsha-hole",
      name: "Prof. Varsha Hole",
      image: "/assets/faculty/prof-varsha-hole.png",
      designation: "Assistant Professor",
      qualification: "M.E. Computer",
      education: ["ME Computer", "BE Computer"],
      contact: [
        "varsha_hole@spit.ac.in",
        "Department of Computer Science and Engineering,",
        "Sardar Patel Institute of Technology, Andheri(W), Mumbai 400058",
      ],
      resume: "",
      data: {
        "Area of Interests": [
          "Image Processing",
          "Machine Learning",
          "Artificial Intelligence",
          "Distributed database",
          "Distributed Systems",
          "Cryptography and System security",
          "Big Data & Analytics",
        ],
        "International Journal: 01": [
          "Varsha Hole, Leena Ragha, Pravin Hole, “Pre-processing of Indian Script Handwritten Document” IJCA Proceedings on International Conference and workshop on Emerging Trends in Technology (ICWET) (1):35-42, 2011 TCET Mumbai. Published by Foundation of Computer Science (ISBN: 978-93-80747-65-3)",
        ],
        "International Paper: 09": [
          "Prof. Varsha Hole, Saurabh Adhau, Shubhangi Shinde, Taufiq Monghal “Globe trotter: An Optimization Travel Sequence Generator”, Second international conference on Electronics, Communication and Aerospace Technology (ICECA 2018), March 2018",
          "Prof. Varsha Hole, Saurabh Yadav, Akshay Rathod, Prathviraj Patil, “Vitamins and Minerals Diagnosis System Using Neural Network “, 2nd International Conference on Inventive Communication and Computational Technologies (ICICCT 2018), April 2018",
          "Prof. Varsha Hole, Amey Bhile ,” Real-Time Environment Description Application for Visually Challenged People”, 2nd International Conference on Computer Networks and Inventive Communication Technologies [ICCNCT 2019], , ISSN: 2367-4512, 23-24 May 2019",
          "Prof. Varsha Hole, Amey Bhile, Gagan Holani, Jay Shah, ” Real Time Scene Descriptor-Android Application” , International Organization of Scientific Research & Development ( IOSRD), 201st International Conference on Future Trends in Engineering, Science and Management | March 27-28, 2019, Delhi, India",
          "Prof. Varsha Hole, Utkarsha Pawar, Karishma Zambare, Roshni Pal , ” Impact of Twitter Data and News Articles on Stock Market “, 2nd International Conference on Computer Networks and Inventive Communication Technologies [ICCNCT 2019], 23-24 May 2019",
          "Varsha Hole, Leena Ragha, Pravin Hole, “Text Line and word segmentation of Indian Script Handwritten Document” International Conference and workshop on Emerging Trends in Technology (ICWET 2012) :117-124, 2012 TCET Mumbai. Published by Foundation of Computer Science (ISBN: 973-93-80864-47-3) (ICWET 2012) icwet (3):25-32 Proceedings published in International Journal of Computer Applications (ISBN: 973-93-80864-47-3)",
          "Varsha Hole, Leena Ragha , “A Survey of Handwritten Document Pre-processing Techniques and Customizing for Indic Script” International Conference and workshop on Emerging Trends in Technology (ICWET 2011):670-674, 2012 TCET Mumbai. Published by Foundation of Computer Science (ISBN: 978-0-615-58717-2)",
          "Varsha Hole, Roshni Patil, Apurva Naik, “Visual Cryptography with watermarking over coloe images” International Journal for research in Engineering Application and Management(ISO 3297:2007 CERTIFIED)ISSN:2454-9150, APR 2016",
          "Varsha Hole,Bhumi Dave,Sumitra Gauda, Priyanka Gawas,”Active learning Method for Interactive Image Retrieval”,International journal of Advanced Engineering,Management and science(IJAEMS)",
        ],
        "National Paper: 02": [
          "Varsha Hole, K. T. Patil, “SIP-based mobility management in next generation networks ”, 2010 at RJIT.",
          "Varsha Hole, Pravin Hole, “Estimation of skew angle in Handwritten Document using Dilate-Thin approach”, EQUINOX 2011 at Terna College of Engineering, Nerul",
        ],
        "Workshop / Seminar Attended": [
          "One day workshop on “8051 – ARM embedded System do it yourself” on 5th Aug 2016",
          "Two days International Symposiym on IOT by Lab – X foundation with SPIT on 3rd and 4th Aug 2016",
          "One week graded ISTE- STTP on “Data Science &big data Analytics” with A++ grade duration 19th Dec To 24 Dec 2016",
          "One week AICTE-ISTE induction program on” Innovative Teaching Learning Practices to achieve Outcome based Education and Accreditation” during 3rd May- 9th May 2018",
          "Co-ordinated One week STTP on “ Hands on : Machine Learning using Python” during 2nd July to 7th July 2018",
          "NPTEL Online certification 8 week course of “Design Analysis of Algorithm” duration Jan- March 2019",
          "1 week National workshop on “Current Trends in information and Database technology”Under TEQIP at VJTI, Matunga.",
          "2 week Teachers Training program by NITTR at Pune.",
          "1 week Training program on “IBM DB2 Training Program” by IBM at BVIT, Navi Mumbai.",
          "2 week ISTE workshop on “Database management systems” by IIT Bombay.",
          "One day seminar on “MATLAB and Simulation”.",
          "2 week ISTE workshop on “Computer Networks” by IIT Bombay",
          "National Conference “AICT2010” at RJIT.",
          "National Conference “EQUINOX 2010” and “EQUINOX 2011” at TERNA college.",
          "International Conference “ICWET 2011” at Thakur college, Kandivali.",
          "International Conference “ICWET 2012” at Thakur college, Kandivali.",
          "“one day workshop on “8051 – ARM embedded System do it your self”, at SPIT on 5th Aug 2016",
          "Two days International Symposiym on IOT by Lab – X foundation with SPIT on 3rd and 4th aug 2016",
          "One week graded ISTE- STTP on “Data Science &big data Analytics” with A++ grade on 19th dec to 24th Dec 2016 at FR. Agnel Vashi",
          "2 – Week ISTE workshop on “Computer Networking by IIT Bombay",
        ],
      },
    },
    {
      id: "prof-nikahat-mulla",
      name: "Prof. Nikahat Mulla",
      image: "/assets/faculty/prof-nikahat-mulla.png",
      designation: "Assistant Professor",
      qualification: "Pursuing Ph.D. (CSE), M.E. Computer Engineering",
      education: [
        "Pursuing Ph. D. (Computer Engineering)",
        "M.E. (Computer Engineering)",
        "B.E. (Computer Engineering)",
      ],
      contact: [
        "nikahat_kazi@spit.ac.in, Extn: 343,",
        "Department of Computer Science and Engineering,",
        "Sardar Patel Institute of Technology, Andheri(W), Mumbai 400058",
      ],
      resume: "",
      data: {
        "Area of Interests": [
          "Soft Computing",
          "Machine Learning",
          "Artificial Intelligence",
          "Computer Programming",
          "Web Programming",
        ],
        "Papers Presented/Published": [
          "“A Hybrid Approach to Abstractive Multi-Document Summarization using Supervised and Unsupervised Learning”, 3rd International Conference on Intelligent Computing and Control Systems [ICICCS 2019], 15th – 17th May 2019",
          "“A Machine Learning Based Review on Development and Classification of QA Systems” , 3rd IEEE Technically Sponsored International Conference on Computing Methodologies and Communication (ICCMC 2019), 27th-29th March 2019",
          "“Deep Bidirectional RNNs Using Gated Recurrent Units and Long Short-Term Memory Units for Building Acoustic Models for Automatic Speech Recognition, International Journal of Research in Signal Processing, Computing & Communication System Design, Vol. 5 Issue 1, 2019",
          "“Breast Cancer Diagnosis And Recurrence Prediction Using Machine Learning Techniques” in IJRET Volume 4, ISSUE 4, April 2015, ISSN 2319-1163",
          "“Various Artificial Intelligence Techniques for Automated Melody Generation” in IJERT Volume 2, ISSUE 7, July 2013, ISSN 2278-0181",
        ],
        "Workshops Attended": [
          "AICTE-ISTE approved 1 week STTP on “Hands On Machine Learning and Deep Learning” at Sardar Patel Institute of Technology held during 13th-18th May 2019",
          "AICTE-ISTE approved 1 week CAS program on Numerical Techniques in Engineering Research aat Sardar Patel Institute of Technology) held during 11th-15 dec 2017",
          "AICTE Sponsored two-day National Seminar on Technologies for Development of Rural Areas (Sardar Patel Institute of Technology) on 27th -28th Nov 2017",
          "Ministry of Electronics & Information Technology, Govt. of India supported two weeks Faculty Development Programme on Introduction to Data Structures & Programming in C (E&ICT Academy, IIITDM, Jabalpur) held during 1st -10th July 2017",
          "ION Pride Faculty Development Program organised by Tata Consultancy Services on 16th January 2017",
          "Two Day Faculty Development Programme on “System and Network Attack Laboratory” organised by Fr. Conceicao Rodrigues College of Engineering, Bandra on February 13th and 14th 2015.",
        ],
        "Books Published": [
          "Mobile Communication and Computing ISBN Number: 9789350770351 Authors: Dipali Koshti, Nikahat Mulla Publisher: Tech-Max Publications, Pune Year: 2016",
          "Soft Computing ISBN Number: 9789350774526 Authors: Dipali Koshti, Nikahat Mulla Publisher: Tech-Max Publications, Pune Year: 2015",
        ],
        "Guest Lectures Conducted": [
          "“Applications of Algorithms” for S.E. (Computer) students at Fr. Conceicao Rodrigues College of Engineering, Computer Engineering Department, Bandra, Mumbai on 27th March 2017",
          "“Neural networks” for B.E. (IT) students at Fr. Conceicao Rodrigues College of Engineering, Information Technology Department, Bandra, Mumbai on 13th October, 2016.",
          "“Applications of Soft computing” for B.E. (Computer) students at Fr. Conceicao Rodrigues College of Engineering, Computer Engineering Department,Bandra, Mumbai on 13th October, 2016.",
        ],
      },
    },
    {
      id: "prof-sheetal-chaudhari",
      name: "Prof. Sheetal Chaudhari",
      image: "/assets/faculty/prof-sheetal-chaudhari.png",
      designation: "Assistant Professor",
      qualification: "M.E.(Computer)",
      education: ["M.E. (Computers)", "B.E. (Computers)"],
      contact: [
        "sheetal_chaudhari@spit.ac.in",
        "Department of Computer Science and Engineering,",
        "Sardar Patel Institute of Technology, Andheri(W), Mumbai 400058.",
      ],
      resume: "",
      data: {
        "Area of Interests": [
          "Computer Networks",
          "Operating Systems",
          "Machine Learning",
        ],
        "Technical Papers Presented/Published at Various Conferences": [
          "Sheetal Chaudhari, Archana Kale, Kishor Kinage, S.G.Bhirud, “Face feature detection and normalization based on eyeball center and recognition”, published in Proceeding of the IEEE International Conference ICFCC 2010, Wuhan, China.(978-1-4222-5824-0)",
          "Sheetal Chaudhari , Archana Kale, “Graph isomorphism + Gabor wavelet for face recognition”, selected for the International Conference ICCA Dec 2010, Pondicherry, India,(978-981-08-7304-2).",
          "Sheetal Chaudhari ,Archana kale, “Face Normalization Enhancing Recognition” selected for IEEE International Conference ICETET, Nov, 2010, Goa, India.(978-0-7695-4246-).",
          "Sheetal Chaudhari, Snehal Bodke, Rupali Chandsarkar, Tulshiram Sule, “Virtual Theater” selected for IEEE International Conference ICETET, Nov, 2010, Goa, India.",
          "Ankita Alshi,Vishakha jagtap, Sheetal chaudhari, “Mobile Browser: Opera Mini 7” published as SLP in ICWET 2013, Mumbai.",
          "Suntej Singh, Aamod Pisat, Gajanan Shewale, Sheetal Chaudhari, “Efficient video streaming and data sharing using cloud computing”, IJIERE, Vol.2, issue 2, 2015.",
          "Sheetal Chaudhari,Radha S. Mani, Pooja Raundale, “SDN Network Vitualization Survey”, IEEE conference WiSPNET 2016, 23 Mar-25 Mar 2016 , Chennai, India.",
          "Malhar Thombare, Rajiv Sukhwani, Priyam Shah, Sheetal Chaudhari, Pooja Raundale, “Implementation of multilevel Feedback Queue Scheduling”, IEEE conference WiSPNET 2016, 23 Mar-25 Mar 2016 , Chennai, India.",
          "Honey Gupta, Aveena Kottwani, Soniya Gogia , Sheetal Chaudhari, “Text Analysis Retrieval of Text Data”, IEEE conference WiSPNET 2016 ,23 Mar-25 Mar 2016 , Chennai, India.",
          "Harshal Parekh,Sheetal Chaudhari, “Improved Round Robin CPU Scheduling Algorithm”, IEEE conference ICSPICC 22-24 Dec.2016, Jalgaon, Maharashtra.",
          "Anushka Ringshia, Harshal Parekh, Apurva Saksena, Sheetal Chaudhari, “Simulation of a Two Head Disk Scheduling Algorithm”, selected for IEEE conference ICECCT, 22 – 24, February 2017, SVS College of Engineering, Coimbatore, Tamil Nadu, India.",
          "Saket Bhelekar, Mrdvika Iyer , Gargee Mehta , Sheetal Chaudhari, “Dynamic Load Balancing in Software Defined Networking”, ICEI 2017, 11-12 May 2017, SCAD College of Engineering and Technology Cheranmahadevi, Tirunelveli, Tamil Nadu, INDIA.",
          "Shebah Nadar, Sheetal Chaudhari, “Proactive-routing path update in Software Defined Networks (SDN), International Conference on Intelligent Computing and Control, 23 and 24 June 2017, Karpagam College of Engineering, Myleripalayam Coimbatore, Tamil Nadu.",
          "Aastha Joshi,Ninad Jagdale, Rajvi Gandhi, Sheetal Chaudhari, “Smart Surveillance System for Detection of Suspicious Behaviour Using Machine Learning” selected for International Conference on Intelligent Computing , Information and Control Systems (ICICCS-2019) 27-28 June 2019, Secunderabad, India.",
          "Naveen Pahuja, Sheetal Chaudhari and Pooja Raundale,” A Review On Recent Approaches to Recommendation System Model Using Distinct Product Features”, selected for 2nd International Conference on “Intelligent Computing Instrumentation and Control Technologies ( ICICICT2019) 5-6 July, Kannur,Kerala, India.",
          "Naveen Pahuja and Sheetal Chaudhari, selected for ” Uncovering significance of feature-selection in recommender system models”,IEEE GCAT 2019,18-20 October 2019, Banglore, India.",
        ],
        Achievements: [
          "Patent – 4484tMUM12015 received for DST funded Innovation & Entrepreneurship Development Center (IEDC) research project “System and Method for Automated Assistance in Event Schedule and Management” in the academic year 2014-15.",
          "“Blindness Simplified” selected by DST funded Innovation & Entrepreneurship Development Center (IEDC) and received grant of 10,000 Rs. for the academic year 2015-16.",
          "Received grant of 30,000 Rs. from Mumbai University for the “Automated Intelligence Surveillance System for Detecting Suspicious Behaviour “, reference number APD/ICD/2018-19/593 dated 16 March 2019.",
        ],
        "Workshop / Course conducted": [
          "Co-coordinator and participant for one week ISTE approved STTP on “Writing research Proposal” organized by I.T. and MCA department, SPIT, 7th Nov.2016 to 11th November 2016.",
          "Organizing committee member for two week ISTE approved STTP on “Parallel Processing and Big Data Analytics” 29th June 2015-10th July 2015, I.T. Department, SPIT.",
          "Organizer for the orientation programme on “Object Oriented Programming Methodology” in Sept. 2013.",
          "Coordinator for one week ISTE approved graded STTP on “Object Oriented Programming Methodology with Lab Exercises” I.T. Department, SPIT, 8th July to 12th July 2013.",
          "Coordinator for one week ISTE approved graded STTP on “Structured Programming Approach with Lab Exercises” I.T. department, SPIT, 31st Dec 2012 to 5th Jan 2013.",
          "Organizer for the orientation program on “Structured Programming Approach” Sept. in 2012.",
        ],
      },
    },
    {
      id: "prof-renuka-pawar",
      name: "Prof. Renuka Pawar",
      image: "/assets/faculty/prof-renuka-pawar.jpg",
      designation: "Assistant Professor",
      qualification: "Pursuing Ph.D. (CSE), M.E.(Computer) ",
      education: ["M.E. (Computer)", "B.E. (Computer)"],
      contact: [
        "renuka_pawar@spit.ac.in, Extn: 341,",
        "Department of Computer Science and Engineering,",
        "Sardar Patel Institute of Technology, Andheri(W), Mumbai 400058.",
      ],
      resume: "",
      data: {
        "Area of Interests": [
          "System and Web Security",
          "Computer networking",
          "Digital Forensics",
          "Computer Graphics and Virtual Reality Systems",
          "Software Testing & Quality Assurance",
        ],
        Activities: [
          "Organized one week short term training program on “Hands on : Machine learning using python”,2nd July 2018 to 7th July 2018.",
          "Conducted a seminar on “Information security concepts” , organized by ACE committee,MCA department , 18th April 2018.",
          "Two days workshop on “Ethical Hacking”at Atharva College of Engineering,Malad..23rd and 24th March 2017",
          "Organized and conducted Two days hands-on workshop on “Cyber Security” on 8th and 9th October 2016.",
          "Resource person for “Ethical Hacking” workshop, conducted a session on SQL injection, SPIT.February 2016",
          "Resource person for “Ethical Hacking” workshop, conducted a session on prerequisite of ethical hacking, SPIT.February 2016",
          "Teaching assistant for two week ISTE workshop on computer networking conducted by IIT Bombay from 28th may to 05th July 2014.",
          "Organized one week graded ISTE-STTP course on “object oriented programming methodology with lab exercises” approved by ISTE during 8th July 2013 to 12th July 2013.",
          "Internal coordination committee 2012",
          "Member of local management committee for International conference ICCICT2012.",
          "Library committee in-charge in March 2010",
          "Maintenance of Exam software in September 2010",
        ],
        "Workshop attended": [
          "NPTEL –AICTE Faculty development programme-Introduction to internet of Things ,12 weeks, Jul-Oct 2018, with consolidated score of 80%",
          "NPTEL –AICTE Faculty development programme-Design Thinking – A Primer ,04 weeks, Feb-March 2019, with consolidated score of 80%",
          "One day International workshop on – Smart contracts with Blockchain and IoT – scheduled on 26th Feb 2018 at VJTI Mumbai.",
          "AICTE-ISTE​ ​Approved​ ​One​ ​Week​ ​STTP​ ​under Career​ ​Advancement​ Scheme(CAS)​ ​on “NUMERICAL​ ​TECHNIQUES​ ​IN​ ​ENGINEERING RESEARCH” 11th​ ​to​ ​15th​ ​December,​ ​2017,SPIT",
          "AICTE Sponsored Two Days National Seminar on “Technologies for Development of Rural Areas” 27th Nov 2017 and 28th Nov 2017,SPIT",
          "Two days workshop on “Android Application development”,Computer department ,SPIT, 24th to 25th September 2016.",
          "one week EC Council CEH v:9 training at Borivali centre during 2 November 2015 to 6 November 2015.",
          "one day Faculty Development program at JP Morgan Chase & Co.Powai,18th July, 2016",
          "Two-Week ISTE Workshop on “Database Management Systems”, organized by IITB 21st to 31st may 2013.",
          "Two-Week ISTE Workshop On “Cyber security”, organized by IITB 10th to 20th July 2014.",
          "One-week workshop on “SOA Basics & IBM DM2”, Organized by SPIT during June 2009.",
          "5-day’s workshop on “Digital Image Processing for Remote Sensing” Organized by IIT Bombay during 01-05 June 2009.",
          "8-days “Faculty training and Development program”, Organized by PATNI, Airoli during 11/12/2009 to 18/12/2009.",
          "One-week STTP on “Software Testing & Quality Assurance”, organized by Thadomal Shahani Engineering College during 12/07/2010 to 16/07/2010.",
          "One week workshop on Linux lab organized by SPIT in February-march 2011.",
          "One week graded ISTE-STTP on “Structured programming approach with lab exercises”, Organized by SPIT during 31/12/2012 to 5/01/2013.",
        ],
        "Papers Published": [
          "Aashay Jain, Anukrit Jain, Yash Pareh and Renuka Pawar, “Augment It Yourself” International Journal of Computer Applications 177(30):32-35, ISBN: 973-93-80900-74-4,January 2020.",
          "Mrunali Chopade, Sana Khan, Uzma Shaikh, Renuka Pawar,” “Digital Forensics: Maintaining Chain of Custody Using Blockchain “3rd International conference on I-SMAC (IoT in Social, Mobile, Analytics and Cloud) (I-SMAC 2019) is being organized on 12-14, December 2019 by SCAD Institute of Technology at Palladam, India",
          "Shruti Sawardekar, Renuka Pawar, “Data Security Approach In Iot Environment”, THE 10th INTERNATIONAL CONFERENCE ON COMPUTING, COMMUNICATION AND NETWORKING TECHNOLOGIES (ICCCNT) 10th ICCCNT 2019,july 6-8 ,2019,IIT Kanpur",
          "Securing Patients Information In Iot Environment, Shruti Sawardekar, Renuka Pawar , “Emergent Research in Mathematics and Engineering – (ICERME-2019)”, during May 17th & 18th, 2019, NIT Agartala.",
          "Abhishek Naik, Apurva Saksena, Kumaresan Mudliar, Ayesha Kazi, Prerna Sukhija,Renuka Pawar,”Secure Complaint bot using Onion Routing Algorithm : Concealing identities to increase effectiveness of complain bot”, IEEE ICECA 2018, IEEE Xplore ISBN:978-1-5386-0965-1",
          "Grishma Alshi, Mansi Dandiwala, Mikhail Cazi, Renuka Pawar,” Interactive Augmented Reality-based System for Traditional Educational Media using Marker-derived Contextual Overlays”, 29, 30, 31 March, 2018, IEEE ICECA 2018, Coimbatore, India, IEEE Xplore ISBN:978-1-5386-0965-1",
          "Aishwarya Shete, Atul Lahade, Tejas Patil, Prof. Renuka Pawar “DHCP Protocol using OTP Based Two-Factor Authentication”, 11-12, May 2018 at Tirunelveli, India,IEEE ICOEI 2018.",
          "V. Kothari, M. Raut, S. Samant and R. Pawar, “Secure gateway defender- a network intrusion detection system,” 18-19 July 2017 International Conference on Computing Methodologies and Communication (ICCMC), Erode, 2017, pp. 398-402.doi: 10.1109/ICCMC.2017.8282717",
          "I. Pandya, S. Jhajj and R. Pawar, “A steganographic approach to mitigate password attacks,” 13-16 Sept. 2017 International Conference on Advances in Computing, Communications and Informatics (ICACCI), Udupi, 2017, pp. 248-253. doi: 10.1109/ICACCI.2017.8125848",
          "Sumedh P Hanmante, Uma Deshattiwar, Renuka Pawar,” Multivariate correlation analysis for network intrusion detection “,International Journal of Scientific & Engineering Research, Volume 7, Issue 4, April-2016, ISSN 2229-5518",
          "Renuka Pawar, Dhananjay R. Kalbande Girish P. Bhole, RadhaShankarmani,” Risk Analysis for Agile Software Development, ICEECMPE –NEW DELHI 2014",
          "Radha Shankarmani, Renuka Pawar, S. S. Mantha, Vinaya Babu,” Agile Methodology Adoption: Benefits and Constraints ” IJCA,2012, ISBN 973-93-80870-45-2.",
          "M. Mehra, M. Agarwal, R. Pawar, and D. Shah. 2011. Mitigating denial of service attack using CAPTCHA mechanism. In Proceedings of the International Conference & Workshop on Emerging Trends in Technology (ICWET ’11). ACM, ICWET’11, February 25–26, 2011, Mumbai, Maharashtra. New York, NY, USA, 284-287. DOI: http://dx.doi.org/10.1145/1980022.1980086",
          "M. Agarwal, M. Mehra, R. Pawar, and D. Shah. 2011. Secure authentication using dynamic virtual keyboard layout. In Proceedings of the International Conference & Workshop on Emerging Trends in Technology (ICWET ’11). ACM, ICWET’11, February 25–26, 2011, Mumbai, Maharashtra. New York, NY, USA, 288-291. DOI: http://dx.doi.org/10.1145/1980022.1980087",
        ],
      },
    },
    {
      id: "prof-aparna-halbe",
      name: "Prof. Aparna Halbe",
      image: "/assets/faculty/prof-aparna-halbe.jpg",
      designation: "Assistant Professor",
      qualification: "Pursuing Ph.D. (CSE), M.E. Computer science",
      education: ["BE information Technology", "ME computer science"],
      contact: [
        "aparna_halbe@spit.ac.in, Extn: 341,",
        "Department of Computer Science and Engineering,",
        "Sardar Patel Institute of Technology, Andheri(W), Mumbai 400058",
      ],
      resume: "",
      data: {
        "Area of Interests": [
          "Big data analytics",
          "Neural network",
          "Analysis of algorithm",
        ],
        "Papers Presented": [
          "“Techniques for Understanding User Usage Behavior on the Internet.” International Journal of Computer Applications 92(7):41-44 by IJCA Journal , April 2014",
          "“Generation of web page from document Image” IJAIS Proceedings on International Conference and workshop on Advanced Computing 2014",
          "“A novel approach to HTML page creation using neural network” International Conference on Advanced Computing Technologies and Applications by ELESEVIER 2015",
        ],
      },
    },
    {
      id: "prof-dayanand-ambawade",
      name: "Prof. Dayanand Ambawade",
      image: "/assets/faculty/prof-dayanand-ambawade.jpg",
      designation: "Associate Professor",
      qualification: "M.Tech (Electronics & Telecomm.), Pursuing (Ph.D.)",
      contact_short: "dd_ambawade@spit.ac.in Phone: 26287250/26707440",
      education: [
        "Currently Pursuing Doctoral research studies at S.P.I.T research centre, Mumbai University",
        "M.Tech (Communication) IIT, Bombay",
        "B.E. (Electronics)",
      ],
      contact: [
        "dd_ambawade@spit.ac.in, Extension: 381",
        "Department of Computer Science and Engineering,",
        "Sardar Patel Institute of Technology, Andheri(W), Mumbai 400058.",
      ],
      resume: "",
      data: {
        "Area of Interests": [
          "My areas of interests are in network architecture, protocols and their performance analysis, traffic management.",
          "I am interested in security in general and statistical and anomaly analysis of network traffic to detect intrusions in particular.",
          "I am studying architecture and performance issues in integrated network defense mechanisms and Open Source Security Information Management (OSSIM).",
        ],
        "Courses Taught": [
          "Computer Networking",
          "Network Security",
          "Mobile and Wireless Networks",
          "Security Management using Open Source Software",
        ],
        Experience: [
          "Installation of Linux Systems and Integrating with Windows Systems.",
          "Connecting Heterogeneous Networks with Samba.",
          "Setting up necessary network services (DNS, SQUID, LDAP, DHCP, FTP, Telnet, Apache/PHP, and Mail etc).",
          "Adding users to the Development Group Server with proper permissions.",
          "Writing Necessary Scripts for the Automation of taking Weekly and Daily Backups.",
          "Network Configuration (Ethernet/Switches/Router/Firewall)",
          "Hardening OS, Kernel Compilation",
          "Server Installation, Configuration & Performance Tuning",
          "Security Configuration and Optimization",
          "Teaching experience in totality : 15 +years",
        ],
        "Professional Affiliations": [
          "Recognized UG & PG Teacher of Mumbai University in Electronics & Telecommunication Engineering",
          "Member of IEEE, Fellow of IETE, Life Member of ISTE.",
        ],
        "Paper Publications": [
          "Pooja Rajan Inamdar and Dayanand D. Ambawade, “Query Language Framework For Pre-silicon Debug” at the International Conference on Applied and Theoretical Computing and Communication Technology (iCATccT – 2015) 29 – 31 October 2015",
          "Roshani Shende and Dayanand Ambawade, “A Novel Approach for Detection of Hardware Trojan Using Side Channel Analysis” at Thirteenth IEEE International Conference on Wireless and Optical Communication Networks (WCON-2016)",
          "Mohsin Tamboli and Dayanand Ambawade, “Secure and Efficient CoAP Based Authentication and Access Control for Internet of Things (IoT)” at IEEE Recent Trends in Electronics, Information and Communication Technology (RTEICT-2016), IEEE Bangalore Section",
          "Ritesh Ramesh Warke and Dayanand Ambawade, “ Enhancing performance of security operations center by reducing alert size in heterogeneous intrusion detection systems” at 2016 International Conference on Innovations in information, Embedded and Communication Systems (ICIIECS)",
          "Rajkumar Waghmode, Rupali Gonsalves & Dayanand Ambawade, “Security Enhancement in Group Based Authentication for VANET”  at 2016 IEEE International Conference on Recent Trends in Electronics, Information & Communication Technology (RTEICT-2016)” on  20th -21st May, 2016",
          "Dayanand Ambawade, Pravin Kedar and J.W. Bakal, “Comprehensive Architecture For Correlation Analysis To Improve The Performance of Security Operation Center” at 4th International Conference on Innovations in Computer Science & Engineering (ICICSE-2016), Springer and CSI, Jul 22-23, 2016 Hyderabad, India.",
          "Salunke, A. ; Ambawade, D. “Dynamic Sequence Number Thresholding protocol for detection of blackhole attack in Wireless Sensor Network” at International Conference on Communication, Information & Computing Technology (ICCICT), 2015 DOI: 10.1109/ICCICT.2015.7045745, IEEE CONFERENCE PUBLICATIONS",
          "Kapadia, K.N. ; Ambawade, D.D. “Congestion aware load balancing for multiradio Wireless Mesh Network” at International Conference on Communication, Information & Computing Technology (ICCICT), 2015, DOI: 10.1109/ICCICT.2015.7045750, IEEE CONFERENCE PUBLICATIONS",
          "Ulka Padwalkar and Dayanand Ambawade “MMRE-AOMDV based Energy Efficient (MAEE) Routing Protocol for WMSNs” at at International Conference on Communication, Information & Computing Technology (ICCICT), 2015",
          "P.S. Patil and Dayanand Ambawade, “Reducing power consumption of smart device by proper management of Wakelocks” at Advance Computing Conference (IACC), 2015 IEEE International Conference, page(s):883 – 88 DOI:10.1109/IADCC.2015.7154832",
          "S. S. Patkar and Dayanand Ambawade, “Secure 3GPP-WLAN authentication protocol based on EAP-AKA” at Advance Computing Conference (IACC), 2015 IEEE International conference, pages 1011 – 1016 DOI:10.1109/IADCC.2015.7154857",
          "Kanchan Naukudkar, Dayanand Ambawade and Dr. J. W. Bakal, “Enhancing Performance of Security Log Analysis using Correlation-Prediction Technique” at International Conference on ICT for Sustainable Development, Springer, Ahmedabad,India July,2015.",
          "Priyanka Malgi and Dyanand Ambawade, “APSAR:Anonymous Position Based Security Aware Routing Protocol for MANETs” at International Conference on Communication Technology” is published in ICCT proceedings, October 5, 2013.",
          "K. Kapadia and Dayanand Ambawade Load,  “Balancing  Techniques for Wireless Mesh Networks: A Review” at International Conference on Recent Trends in Computer and Electronics Engineering (ICRTCEE) on January,11 2014, Shah and Katchhi, Chembur Mumbai-400088.",
          "Vaibhav Kohli and Dayanand Ambawade, “Costing for Cloud users and Remediation for its Data Collection process by Pre-Recovery Mechanism Algorithm ”  at International Conference on Circuits, Systems,Communications and Information Technology Applications ( CSCITA 2014 IEEE), April 4-5, 2014.",
          "Abhijeet Salunke and Dayanand Ambawade, “Trust and Reputation Model overcoming Security Threats in Wireless Sensor Network” at International Conference on Recent Advances and Challenges in Engineering and Management April 3-4, 2014 (R A C E M -2014) ,Vidyalankar Institute of Technology, Vidyalankar Marg, Wadala (East), Mumbai 400 037",
          "Nirav Patel and Dayanand Ambawade, “Approximation of Resource Provisioning cost in Cloud Computing using  algorithm with reduced scenario set” at ICC-2014 TECHNOVISION 2014, STES’s Sinhgad Institute of Technology and Science, Pune 41, April 5-6 , 2014.",
          "Bhushan Choudhari, Prathmesh Gothankar, Dayanand Ambawade & et al, “Wireless Network Security using Dynamic Rule Generation of Firewall” in International  Conference on Communication, Information and Computing Technology (ICCICT-2012) at Mumbai, 2012.",
          "Pritesh Chandalia, Udaykumar Lokhande, Dayanand Ambawade et al. “Interference Analysis of IEEE 802.11n” in International  Conference on Communication, Information and Computing Technology (ICCICT-2012) at Mumbai, 2012.",
          "Srushtie Kaley and Dayanand Ambawade, “Mobile Radio Network Planning and Optimization” at International Conference (ICGTI-2012) at Mumbai, 2012.",
          "Dayanand Ambawade, Tejas Potdar  and Deepak Karia, “Statistical Tuning of Walfisch-Ikegami Model in Urban and Suburban Environments,” ams, pp.538-543, 2010 Fourth Asia International Conference on Mathematical/Analytical Modeling and Computer Simulation, 2010, Malaysia",
          "Ujwala Potdar, Dayanand Ambawade et al, “Real-Time Video Encryption for H.263 Videos” at  International Conference on Distributed Frameworks for Multimedia Applications (DFmA-2010), Indonesia.",
          "P. V. Kasambe, R. G. Sutar, and D. D. Ambawade, “Real-Time Heart Rate Variability Analysis Using Virtual Instrumentation” International Conference at VESIT, Mumbai 2009.",
          "Dayanand Ambawade and Prachi Gharpure, “Modeling and Simulation of Functional Extension for Network Switches in Network Simulator (ns-2)” IEEE-Colloquium and International Conference, Mumbai, Feb 2008.",
        ],
        "Books Published": [
          "“Linux Lab: Hands-on Linux” by Dayanand Ambawade and Deven Shah, Wiley-Dreamtech Pubication, 2009.",
          "“Advanced Computer Network” by Dayanand Ambawade  and Deven Shah , Wiley-Dreamtech Publication 2010.",
          "“Linux Labs and Open Source Technologies” by Dayanand Ambawade and Deven Shah, Wiley-Dreamtech Publication 2014.",
        ],
        "Awards & Honors": [
          "Incentive award for excellence in teaching (October 2010)",
          "Recipient of 1st “Pradnyavant-2006” award of Swa. Dadasaheb Undalkar Smarak Samitee, Karad, Maharashtra,conferred upon me by former Hon. Chief Justice of Supreme Court, Justice P.B. Sawant.",
          "Guest of Honor, National Information Technology Colloquium-2008 (NITC-08) at Sri Venketeshwara University, Tirupathi, A.P.",
          "Best project guide award at “Mind’s Eye” A National Level Project Showcase & Competition, 16-17 April 2007.",
        ],
        "Service to College/University": [
          "Head of Networking Core Team of Institute",
          "Chairman of Forum of Electronics & Telecomm. Students (FETs).",
          "Coordinator of DTE MBA/MMS/MCA Admission Process, 2004/2005",
          "Secretary of National Conference on Biomedical Engineering, 2006 (NCBME-2006)",
          "Expert Member, Staff Selection Commission (SSC) of Govt. of India",
          "Expert Member, Staff Selection of Engineering Colleges.",
          "Advisor to VES Polytechnic, Chembur, Mumbai.",
        ],
        "Research and Consultancy Activities": [
          "Successfully completed funded project on “Security Visualization” of DST, Govt. of India. Fund Amount Rs.100000/-",
          "Conducted a one-week workshop on “Linux Server, Network, Security Administration & Troubleshooting” at L&T (IT Division). Amount is Rs.96000/-",
          "Project coordinator of Aakash R&D with IIT-B & NMEICT, MHRD, Government of India.",
        ],
        "Workshops/Refresher courses/Training programs/Seminars attended": [
          "Two Week Course on “Networking: Theory to Practice” Organized by ISTE-Chapter of Fr. CRCE Bandra, 2003",
          "One Week Course on “Induction Training Program” at Bharati Vidyapeeth’s College of Engineering, 1999.  Seminar on “Telecommunications” at Fr. Agnel I.T. Vashi, 2002.",
        ],
        "Workshops/Refresher courses/Training programs/Seminars conducted as a resource person":
          [
            "Workshop on “GNU/LINUX” in SPACE 2003, Mumbai",
            "Workshop on “GNU/LINUX” at Vivekanand College of Engineering, Chembur, 2004.",
            "Conducting Certificate Courses on: “Linux System Administration & Networking” Unix/Linux OS Essentials (LCE-1) Linux System Administration (LCE-2) Linux Networking & Security Administration (LCE-3)",
            "Delivered Seminar on “Wireless Transmission & Satellite systems” at Air Force Station, Kanheri hills, Thane on “World Telecom Day”, 2004.",
            "Conducted Seminar-cum-Workshop on “Mobile Computing” at SPCE, 2004.",
            "Conducted Seminar-cum-Workshop on “System Security” at SPCE, 2005.",
            "Conducted One-Week STTP course on “Linux System, Server, Network & Security Administration” at St. Francis IT. Mumbai, Dec, 2005.",
            "Conducted Seminar-cum-Workshop on “Open Source Security Tools & Management” at ISTE-STTP on “OPEN SOURCE SOFTWARE” -2006, K.J. Somaiya Engineering College, Vidyavihar, Mumbai.",
            "Conducted Seminar-cum-Workshop on “Open Source Security Tools & Management” at NIMMIT-2006, S.P. Jain Institute of Management & Research (SPJIMR), Mumbai.",
            "Conducted Two-Day Seminar-cum-Workshop on “Hands-on Computer Networking” at ISTE-STTP, Rajiv Gandhi Institute of Technology, Mumbai, June 11 & 12, 2006.",
            "Conduced One-Day Seminar-cum-Workshop on “WLAN Security” at ISTE-STTP Course, D.J. Sanghavi COE, Electronics Engg. Mumbai, Dec, 18, 2007.",
            "Delivered Seminar on “Wireless Networks Security (WLAN, GSM, CDMA, Bluetooth Security)” ISTE-STTP Course D.J. Sanghavi COE, Computer Dept. Mumbai Jan, 11, 2008.",
            "Delivered keynote address on “Challenges of Information Security from Technological Viewpoint” at National Information Technology Colloquium (NITC-2008) at SRI Venketeshwara University, Tirupathi, A.P. on March 6, 2008.",
            "Conducted Seminar-cum-live-demo on “Experimental Networking” at Atharva College of Engineering, Mumbai on April 3, 2008.",
            "Conducted 6-days Hands-on Workshop on “Linux System, Network, Security Administration and Customization” on June 6-11, 2009.",
            "Delivered Seminar on “Challenges of Information Security from Technological Viewpoint” at Thakur College of Engineering & Technology, Kandivali Mumbai on Dec 19,2008",
            "Conducted “Hands-On Workshop on Information Security” at Sardar Patel Institute of Technology, Mumbai on Jan 24 & 25, 2009.",
            "Corporate Training on “Linux System, Network and Security administration” as at L&T Info. Tech. on Feb 23-27,2009",
            "Conducted 48-Hours Certificate Course on “Linux System, Network and Security Administration” on March-Apr,2009",
            "Conducted course on “Cloud: How to make Private cloud” at Sardar Patel Institute of Technology, Mumbai 2010.",
            "Conducted one-day Workshop on “NS2- Network Simulator” at D.J. Sanghavi COE,  Vile-Parle Mumbai. 2010.",
            "Delivered expert talk on “Wireless Network” at K.C. COE, on 28th Jan, 2011 in Orientation Program for staff members.",
            "Conducted workshop on “Network Security Issues” at ICWET International Conference, TCET, Kandivali, Mumbai. 2011",
            "Conducted Seminar-cum-Workshop on “Wireless Security (WLAN, GSM and 3G)” at NMMIS, Mukesh Patel IT, Vile Parle 2011.",
            "Conducted seminar-cum-workshop on “WLAN Implementation and Security” at Xavier College of Engineering at Mahim, Mumbai 2011.",
            "Conducted seminar-cum-workshop on “NS-2” at Fr. Angel College of Engineering at Bandra, Mumbai 2011.",
            "Conducted seminar-cum-workshop on “IDS/IPS” at MIT COE, Pune, 2011.",
            "Conducted a two-week graded ISTE-STTP on “Computer Communication Networks with Lab Exercises” November-December, 2011.",
            "Conducted seminar-cum-workshop on “Network Security using Open Source Tools” at ICWET-2012, TCET, Kandivali, Mumbai, 2012.",
            "Conducted Two-Day Workshop on “NS-2” at Fr. Angel College of Engineering at Bandra, Mumbai May, 2012.",
            "Two-Day Workshop on “Hands-on -Information Security and Ethical Hacking” at S.P.T.T. on 7th and 8th September,2013.",
            "One-Day Workshop on “Wireless Network Security” at S.I.E.S Engineering and Technology, Nerul, Navi-Mumbai on 27th November, 2013.",
            "Two-Day Workshop on “Hands-on Lab-Network Simulator NS-2” at S.P.I.T. on 5th and 12th February,2014.",
            "Workshop on “Open Source Technologies” at FRCCOE, Vashi on 7th and 8th July 2014.",
            "Two-Day Workshop Conductd on “Linux System/Network/Server and Security Administration” at R.A.I.T. Nerul Navi Mumbai",
            "Two-Day Workshop on “Network Programming Laboratory” at Xavier Institute of Engineering, 5 and 6th January,2015",
            "Two-Day Workshop on “Open Source Security  and Web Security” at Xavier Institute of Engineering on 2nd  and 3rd Feb, 2015.",
            "One-Day Workshop on “Hands-On Computer Communication Networks” at Sardar Patel Institute of Technology Mumbai 7th February,2015.",
            "Two-Day Workshop on  “System and Network Attack Laboratory” at Fr. C.R. COE, Bandra on 13th and 14th February,2015",
            "One-Day Workshop on “WLAN Security and NS-2” at  on Wireless Network and Cognitive Radio, 9th March to 13th March 2015, V.J.T.I. Mumbai",
            "A Two-Day Hands-on Workshop on “Ethical Hacking and Network Defenses” at Sardar Patel Institute of Technology, Mumbai on 14th and 15th March,2015",
          ],
        Accomplishments: [
          "Developed Linux Networking Lab.",
          "Developed Information Security Lab.",
          "Developed Mobile Computing Lab.",
          "Developed College Network.",
          "Developed Network Management Lab.",
        ],
        "Major Assignments": [
          "Worked on major Networking Design, OS/Server Installation and Configuration",
          "Network Configuration (Ethernet/Switches/Router/Firewall)",
          "Hardening OS, Kernel Compilation",
          "Server Installation, Configuration & Performance Tuning",
          "Security Configuration and Optimization",
        ],
        "System Software Exposure": [
          "Security  Tools: Tripwire, TCPWrappers, SSH, iptables Firewall, Snort IDS, Nessus Vulnerability Assessment, PGP, Rootkits, ZoneAlarm, DansGauardian etc.",
          "Security Management: OSSIM, GFILanguard and ManageEngineetc.",
          "Network Monitoring:  Ethereal, Ntop, Tcpdump, Nagios, Etherape etc.",
          "Installation: Special procedures (Network, Custom, Kickstart, Automate)",
          "Backup: Tar, ISO making, MD5 etc.",
          "Networking: NIS, NIS+, NFS, SQUID, LDAP, DNS, FTP Ethernet, Send Mail, Qmail Apache/PHP/MySql, IIS, SAMBA etc.",
          "Networking H/W:   Cisco Routers & PIX Firewall Setting, Wireless Router/APs",
          "Protocols: TCP, IP, ARP, DHCP, SMTP, HTTP, FTP, TELNET etc.",
          "Web/App Servers: Apache/PHP/Mysql, Squid Web Proxy Server, MySQL etc.",
          "Software Applications: Open Office, MSOffice, XV, Gnuplot, Latex, Lyx,",
          "Wireless Tools: NetStumbler, Kismet, AirSnort and Airdefence etc.",
          "Network Simulators: NS-2, OMNet++ and OPNET etc.",
          "Forensics Tools: Incidence Handling, Building Response, FIRE LiveCD, Autopsy etc.",
          "Log Analysis: Centralised log server (syslog-ng), webalizer and SARG etc.",
          "Network Management Tools: Nagios, Icinga, Cacti, OpenNMS, OpManager etc.",
        ],
      },
    }
];