export const menuItems = [
	{
	    title: 'Home',
		url: '/',
	},
	{
		id: 1,
		title: 'Academics',
		submenu: [
			{
				title: 'UG CSE',
				url: '/academics',
			},
			{
				title: 'PG MCA',
				url: 'https://mca.spit.ac.in',
			},
		],
	},
	{
		title: 'Faculty',
		url: '/faculty',
	},
	{
		id: 2,
		title: 'Events',
		submenu: [
			{
				title: 'Competitions',
				url: '/events/competitions',
			},
			{
				title: 'Workshops',
				url: '/events/workshops',
			},
			{
				title: 'Expert Talks',
				url: '/events/expert-talks',
			},
			{
				title: 'Value Added Courses',
				url: '/events/value-added-courses',
			},
		],
	},
	{
		id: 3,
		title: 'Committees',
		submenu: [
			{
				title: 'ACSES',
				url: 'https://acses.spit.ac.in',
			}
		],
	},
];