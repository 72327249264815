import { useEffect, useState } from "react";
import styles from '../../styles/academics/academics.module.css'
import axios from 'axios';


const TimeTable = () => {

    const [data, setData] = useState("");
    const baseUrl = "https://cse.spit.ac.in/api"

    const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}/generals/timetable`);
          console.log("response data:", response.data);
          setData(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };


    useEffect(() => {
        fetchData()
    }, []);

    return (
        <div>
            <h2> Time Table </h2>
            <br />
            {
                data != "" && data.map((item, index) => (
                    <ul className={styles.academicLinks} key={index}>
                        <li> <a href={baseUrl+'/api/files/'+item.file.replace('/resources/resources/', '')} target="_blank" rel="noreferrer"> {item.title} </a> </li>
                    </ul>
                ))
            }
        </div>
    );
}

export default TimeTable;