import styles from '../../styles/notices/notices.module.css'
// import { noticeData } from '../../metadata/noticesList';
import axios from 'axios';
import { useState, useEffect } from 'react';


export default function Notices() {

    const [noticeData, setNoticeData] = useState("");
    const baseUrl = "https://cse.spit.ac.in/api"

    const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}/generals/notice`);
          setNoticeData(response.data);
        } catch (error) {
        //   console.error("Error fetching data:", error);
        }
      };

      useEffect(() => {
        fetchData()
    }, []);


    return (
        <div>
            <div className={styles.topBanner}>
                <span className={styles.bannerTitle}> Find All Important Notices Here </span>
            </div>
            <div className={styles.outerContainer}>
                <table className={styles.noticeTable}>
                    <thead className={styles.noticeTableHead}>
                        <tr>
                            <th> SR No. </th>
                            <th> Title </th>
                            <th> Upload Date </th>
                        </tr>
                    </thead>
                    <tbody className={styles.noticeTableBody}>
                        {
                            noticeData && noticeData.map((item, index) => (
                                <tr key={index+1}>
                                    <td> {index+1} </td>
                                    <td> <a className={styles.noticeLink} href={baseUrl+'/api/files/'+item.file.replace('/resources/resources/', '')} target="_blank" rel="noreferrer"> { item.title } </a> </td>
                                    <td> {item.date} </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}