import './App.css';
import Home from './pages/home';
import Header from './pages/Header';
import Footer from './pages/Footer';
import {Routes, Route, Navigate } from 'react-router-dom';
import Workshops from './pages/events/workshops';
import ExpertTalks from './pages/events/expertTalks';
import ValueAddedCourse from './pages/events/valueAddedCourses';
import TeachingStaff from './pages/faculty/teachingStaff';
import AboutUs from './pages/about us/aboutus';
import FacultyInfo from './pages/faculty/facultyInfo';
import Competitions from './pages/events/competition';
import { PageNotFound } from './pages/PageNotFound';
import Notices from './pages/notices/notice';
import Academics from './pages/academics/Academics';

function App(props) {

    return (
        <div className="App">
            <Header alt="header"/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/faculty" element={<TeachingStaff />} />
                <Route path="/faculty/:slug" element={<FacultyInfo />} />
                <Route path="/academics/" element={<Academics />} />
                <Route path="/events/value-added-courses" element={<ValueAddedCourse />} />
                <Route path="/events/expert-talks" element={<ExpertTalks />} />
                <Route path="/events/workshops" element={<Workshops />} />
                <Route path="/events/competitions" element={<Competitions />} />
                <Route path="/notices" element={<Notices />} />
                <Route path="/404-page-not-found" element={<PageNotFound />} />
                <Route path="*" element={<Navigate to="/404-page-not-found" />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;