export const data = {
    "message-from-hod": {
        "title": "Message from HOD",
        "name": "Dr. Dhananjay Kalbande",
        "designation": "HOD of Computer Science and Engineering Department",
        "message": [
            "Dear Students",
            "I would like to extend my warmest welcome to the Department of Computer Science and Engineering. The Department of Computer Science and Engineering was established in the year 2021 introducing two new UG Programs - B.Tech in CSE [AIML] and B.Tech in CSE [Data Science] with the aim of providing students with the necessary skills to excel in the rapidly evolving tech industry. From 2023, the two programs are merged and the course offered is B.Tech in CSE. Currently, the Department offers 1 UG program [ B.Tech CSE ] and 1 PG Program [ MCA ].",
            "We pride ourselves on being pioneers to educate tomorrow's visionaries, conducting research, and leading a broad range of initiatives that validate the transformative power of Computer Science and Engineering and Computer Applications.",
            "Our innovative academic and research institution gives serious students like you the skills needed to launch successful careers. Through high-quality academic programs enriched with today's National Education Policy(NEP-2020) that offer a unique blend of teaching, research, activity-based learning including Seva Satva, minors etc. that strengthens your ability to solve problems, you can expand your knowledge base, work independently, think critically, and find new ways to apply Science and Engineering concepts to benefit society.",
            "Students work alongside faculty members who are leaders in their respective disciplines, have access to labs equipped with the latest technologies, and are connected to a comprehensive University providing breadth and depth of opportunities. As a result, our students acquire critical thinking capabilities as well as practically oriented skills that are prized by prestigious companies and academic institutions of National and International Importance.",
            "All faculty members of the Department of Computer Science and Engineering are strongly committed to supporting your success. In return, we know you will study hard, seek out and respect the opinions of faculties and find opportunities to give back to your society. The efforts are consciously planned for the overall development of students. This leads our students to become competent, innovative, socially-responsible and dynamic. We are confident that our students will emerge as assets not only to this institution but also to the entire society, and the nation at large.",
            "I wish you all very best for the successful career ahead."
        ]
    },
    "important-links": [
        {
            "title": "Notices",
            "link": "/notices"
        },
        {
            "title": "Academics - CSE",
            "link": "/academics"
        },
        {
            "title": "Faculties",
            "link": "/faculty"
        },
        {
            "title": "Expert Talks",
            "link": "/events/expert-talks"
        }
    ],
    "about-us-carousal-images": ["Carousel7.jpg", "Carousel8.jpg", "Carousel9.jpeg", "Carousel10.jpeg", "Carousel1.jpeg", "Carousel2.jpeg", "Carousel3.jpeg", "Carousel4.jpeg", "Carousel5.jpeg", "Carousel6.jpeg"],
    "about-the-department": [
        "The Department of Computer Science and Engineering was established in the year 2021 introducing two new UG Programs - B.Tech in CSE [AIML] and B.Tech in CSE [Data Science] with the aim of providing students with the necessary skills to excel in the rapidly evolving tech industry. From 2023, the two programs are merged and the course offered is B.Tech in CSE. The department consists of 14+ faculty members, 3 technical assistants and more than 220 students working towards their Bachelors’, Masters’ and Doctoral degrees. The department attracts high quality students from all over Maharashtra and also from the country. In the recent past, we have benchmarked our curriculum with select institutions of higher learning around the world and currently we are carefully reviewing the impact of these consequent changes with a view to make our programs even more strong and competitive. The department also attracts the interaction with the IT industries to provide training to the students.",
        "Graduates from this department will have a deep understanding of both the theoretical and practical aspects of computer science and engineering. They will be well equipped with a broad range of skills that can be applied in many industries, including tech, finance, healthcare, transportation, entertainment, etc. The department offers a comprehensive curriculum that includes both theoretical and practical courses, making it a highly sought-after field of study. The department's graduates have a wide range of career opportunities in various industries, and their skills are essential to the development of new technologies that drive innovation."
    ],
    "vision_mission": {
        "Computer Science And Engineering": {
            "vision": {
                "text": "To develop globally competent and ethical professionals in Computer Science and engineering and enable them to contribute to society."
            },
            "mission": {
                "list": [
                    "To provide rigorous, high-standard, multidisciplinary curriculum and innovative T-L-E-A processes and ensure a stimulating academic environment.",
                    "To promote research and innovations through collaborations.",
                    "To develop requisite attitudes and skills, besides providing a strong knowledge foundation.",
                    "To foster ethics and social responsibility among stakeholders and imbibe a sense of contribution."
                ]   
            },
            "peo": {
                "text": "The B.Tech(Computer Science & Engineering) Graduates after four to five years will :",
                "list": [
                    "Conceive, design, analyze, develop, test, deploy, and maintain the software and hardware products, services, and systems.",
                    "Engage in the advancement of Computer Science and engineering recognizing the need to stay abreast of the latest technology while serving industry, public and private institutions, academia, or any other chosen field.",
                    "Demonstrate excellent computational, communication, teamwork, finance, and project management skills to analyze and solve multi-disciplinary real-life problems.",
                    "Assume leadership in the chosen vertical and demonstrate sensitivity to social, environmental, national, and global issues."
                ]
            },
            "po": {
                "text": "At the end of the programme, a student will be able to: ",
                "list": [
                    "Engineering Knowledge: apply knowledge of mathematics, science, and engineering to solve the real-life problems in computer-based systems.",
                    "Problem Analysis: identify, analyze and formulate computer science & engineering related problem and conduct new experiments",
                    "Design/development of Solutions: design computer-based systems to meet desired needs within realistic constraints.",
                    "Conduct Investigations of Complex Problems: design and conduct experiments to interpret data and analyze the results.",
                    "Modern Tool usage: use modern tools for engineering practice.",
                    "The Engineer and Society: understand the impact of an engineer in general and Computer Science Engineer in particular on societal, safety, health, legal, and cultural issues.",
                    "Environment and Sustainability: understand the need and impact of computer science & engineering solutions on environment and its sustainability.",
                    "Ethics: take professional decision with a sense of ethical responsibility.",
                    "Individual and Team Work: function effectively as an individual, and as a member or leader in multidisciplinary and/or cross cultural teams.",
                    "Communication: communicate effectively and design documentation.",
                    "Project Management and Finance: apply engineering & management principles in day-to-day life while managing projects in a multidisciplinary environment.",
                    "Life-long Learning adapts to the lifelong learning process in the continually changing technological context."
                ]
            },
            "pso": {
                "list": [
                    "Apply the principles of CSE and AI, Machine Learning, Data Science, UI/UX, Blockchain, Quantum Computing, Security, AR-VR technologies, IOT and use Open-source technologies for problem-solving under realistic constraints.",
                    "Develop and deploy software products and systems, demonstrate leadership, teamwork, communication skills, LLL attitude, entrepreneurial mindset, and social sensitivity."
                ]
            }
        },
        "Masters in Computer Applications": {
            "vision": {
                "text": "To develop globally competent and ethical professionals in Computer Science and engineering and enable them to contribute to society."
            },
            "mission": {
                "list": [
                    "To provide rigorous, high-standard, multidisciplinary curriculum and innovative T-L-E-A processes and ensure a stimulating academic environment.",
                    "To promote research and innovations through collaborations.",
                    "To develop requisite attitudes and skills, besides providing a strong knowledge foundation.",
                    "To foster ethics and social responsibility among stakeholders and imbibe a sense of contribution."
                ]   
            },
            "peo": {
                "text": "The MCA Graduates after four to five years of their graduation will:",
                "list": [
                    "Design, analyze, develop, test, deploy, and maintain the software products, services, and systems.",
                    "Engage in applying the advancement of ICT and stay familiar with the latest technology trends and skills while serving industry, public and private institutions, academia, or any other chosen field.",
                    "Demonstrate excellent computational, communication, teamwork, finance, and project management skills to analyze and solve real-life computational problems, enhance the efficacy and effectiveness of an existing application, or develop a new application.",
                    "Assume leadership in the chosen vertical and demonstrate sensitivity to GSC[Global Social Responsibility]."
                ]
            },
            "po": {
                "text": "At the end of the programme, a student will be able to: ",
                "list": [
                    "Computational Knowledge: Apply knowledge of computing fundamentals, computing specialization, mathematics, and domain knowledge appropriate for problem-solving techniques to formulate solutions for Information System",
                    "Problem Analysis: Conceptualize knowledge and background to be able to analyze a problem and identify and define the computing requirements for its solution",
                    "Design / Development of Solutions: Design a new system to meet certain specifications.",
                    "Conduct investigations of complex computing problems: Conduct investigations of complex problems using analysis, modeling, interpretation of data, and synthesis of information in order to reach valid conclusions",
                    "Modern Tool Usage: Design, monitor, manage, test, control, and evaluate an existing computer-based system, process, component, or program and provide valid conclusions using software modeling, warehousing, mining, and networking tools for application development.",
                    "Professional Ethics: Apply ethical principles and adhere to professional ethics and responsibilities and norms of Application Development.",
                    "Life-long Learning: Recognize the need for and an ability to engage in lifelong learning.",
                    "Project management and finance: Demonstrate knowledge and understanding of IT and management principles and apply these to one’s own work, as a member or a leader of the team, to manage IT projects.",
                    "Communication Efficacy: Communicate effectively using classic and modern technology with IT professionals and with society at large through report writing as well as technical presentations",
                    "Societal and Environmental Concern: Understand the impact of the applications and services in societal and environmental contexts, and exhibit the knowledge of, and need for sustainable development.",
                    "Individual and Teamwork: Function effectively as an individual, and as a member or leader of a team.",
                    "Innovation and Entrepreneurship: Create opportunities to produce successful Entrepreneurs."
                ]
            },
            "pso": {
                "list": [
                    "Apply the principles of ICT [Information and Communication Technology] and technologies such as AI-ML, Data Science, UI/UX, Blockchain, Software testing, RPA, security, cloud, IOT, Mobile computing, and open-source for real-life problem-solving.",
                    "Develop and deploy software products and services, demonstrate leadership and teamwork, and communicate effectively."
                ]
            }
        }
    }
};