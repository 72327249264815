import { useEffect, useState } from "react";
import styles from '../../styles/academics/academics.module.css'
import axios from 'axios';

const Internships = () => {
    const [data, setData] = useState("");
    const baseUrl = "https://cse.spit.ac.in/api"

     const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/generals/internships`,{});
        setData(response.data);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
        fetchData()
    }, []);

    const industryInternships = Boolean(data?.length) ? data.filter(internship => internship?.category === 'industry') : [];
    const researchInternships = Boolean(data?.length) ? data.filter(internship => internship?.category === 'research') : [];

    return (
        <div>
            <h2>Internship Statistics</h2>
            <br/>
            <div>
                <h3>Industry Internship</h3>
                <br />
                  {
                    industryInternships ? industryInternships.map((internship, index) => (
                      <ul className={styles.academicLinks} key={index}>
                        <li key={index}>
                            <a href={baseUrl+'/api/files/'+internship.pdf.replace('/resources/resources/', '')} target="_blank" rel="noopener noreferrer">{internship.year}</a>
                        </li>
                      </ul>
                    ))
                    :
                        <h4>Nothing to show</h4>
                  }
            </div>
            <div>
                <h3>Research Internship</h3>
                <br />
                <ul>
                  {
                    researchInternships ? researchInternships.map((internship, index) => (
                      <ul className={styles.academicLinks} key={index}>
                        <li key={index}>
                            <a href={baseUrl+'/api/files/'+internship.pdf.replace('/resources/resources/', '')} target="_blank" rel="noopener noreferrer">{internship.year}</a>
                        </li>
                      </ul>
                    ))
                    :
                      <h4>Nothing to show</h4>
                  }
                </ul>
            </div>
        </div>
    );
};

export default Internships;
