import { useEffect, useState } from "react";
import styles from '../../styles/academics/academics.module.css';
import { data } from "../../metadata/aboutDeptData";

const Vision_Mission = () => {

    const [vmData, setvmData] = useState(null);

    useEffect(() => {
        setvmData(data);
    }, []);

    return (
        <div>
            { vmData !== null && 
            (
                <>
                    {Object.keys(vmData["vision_mission"]).map((key, index) => (
                        <div key={key}>
                        <h1>{key}</h1>
                        <br/>
                        <div className={styles.vmCard}>
                            <h3 className={styles.vmTitle}> Vision </h3>
                            <div className={styles.vmContent}>
                                <span> {vmData["vision_mission"][key]["vision"]?.text } </span>
                            </div>
                        </div>

                        <div className={styles.vmCard}>
                            <h3 className={styles.vmTitle}> Mission </h3>
                            <div className={styles.vmContent}>
                                <table className={styles.vmTable} border="1">
                                    <tbody>
                                    {
                                        vmData["vision_mission"][key]["mission"]?.list.map((item, index) => (
                                            <tr key={index}>
                                                <th className={styles.vmTableTH}> M{index+1} </th>
                                                <td className={styles.vmTableTD}> {item} </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={styles.vmCard}>
                            <h3 className={styles.vmTitle}> Program Educational Outcomes (PEOs) </h3>
                            <div className={styles.vmContent}>
                                <span> { vmData["vision_mission"][key]['peo']?.text } </span>
                                <table className={styles.vmTable} border="1">
                                    <tbody>
                                    {
                                        vmData["vision_mission"][key]["peo"]?.list.map((item, index) => (
                                            <tr key={index}>
                                                <th className={styles.vmTableTH}> PEO {index+1} </th>
                                                <td className={styles.vmTableTD}> {item} </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={styles.vmCard}>
                            <h3 className={styles.vmTitle}> Program Outcomes (POs) </h3>
                            <div className={styles.vmContent}>
                                <span> {vmData["vision_mission"][key]['po']?.text} </span>
                                <table className={styles.vmTable} border="1">
                                    <tbody>
                                    {
                                        vmData["vision_mission"][key]["po"]?.list.map((item, index) => (
                                            <tr key={index}>
                                                <th className={styles.vmTableTH}> PO {index+1} </th>
                                                <td className={styles.vmTableTD}> {item} </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={styles.vmCard}>
                            <h3 className={styles.vmTitle}> Program Specific Outcomes (PSOs) </h3>
                            <div className={styles.vmContent}>
                                <table className={styles.vmTable} border="1">
                                    <tbody>
                                    {
                                        vmData["vision_mission"][key]["pso"]?.list.map((item, index) => (
                                            <tr key={index}>
                                                <th className={styles.vmTableTH}> PSO {index+1} </th>
                                                <td className={styles.vmTableTD}> {item} </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                    ))}
                </>
            )
            }
        </div>
    );
}

export default Vision_Mission;