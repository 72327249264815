import styles from '../../styles/academics/academics.module.css'
import { useState } from 'react';
import { Accordion } from './Accordion';
import TimeTable from './TimeTable';
import Syllabus from './Syllabus';
import Vision_Mission from './Vision-Mission';
import Results from './Results';
import Internships from './Internships';
import Placements from './Placements';
import HigherStudies from './HigherStudies';
import Others from './Others';
import StudAchi from './StudAchi';

export default function Academics() {
    const [selected, setSelected] = useState('vision-mission');

    function Content(params) {
        switch(selected) {
            case 'vision-mission':
                return <Vision_Mission />;
            case 'time-table':
                return <TimeTable />;
            case 'syllabus':
                return <Syllabus />;
            case 'results':
                return <Results />;
            case 'internships':
                return <Internships />;
            case 'placements':
                return <Placements />;
            case 'higherStudies':
                return <HigherStudies />;
            case 'others':
                return <Others />;
            case 'stud_achi':
                return <StudAchi />;
            // case 'projects':
            //     return (
            //         <div>
            //             <h2>Projects</h2>
            //             <br />
            //             <p>Coming Soon...</p>
            //         </div>
            //     );
            default:
                return (
                    <div>
                        <h2>Wrong Route/Option selected</h2>
                        <br />
                        <h4 style={{color: 'blue', cursor: 'pointer'}} onClick={() => setSelected('vision-mission')}>Click Here...</h4>
                    </div>
                );
        }
        
    }

    return (
        <div>
            <div className={styles.topBanner}>
                <span className={styles.bannerTitle}> Academics Section of Computer Science and Engineering (CSE) </span>
            </div>
            <div className={styles.outerContainer}>
                <Accordion selected={selected} setSelected={setSelected} className={styles.gridLeft} />
                <div className={styles.gridRight}>
                    <div id={styles.data}>
                        {
                            Content()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}