import styles from "../../styles/faculty/facultyinfo.module.css";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { facultyData } from "../../metadata/facultyData";
import { BsArrowLeft } from "react-icons/bs";
import axios from 'axios';

function renderData(data) {
  if (typeof data === 'object' && data !== null) {
    return (
      <ul className={styles.list}>
        {Object.entries(data).map(([key, value]) => (
          <li key={key}>
            <strong></strong>{renderData(value)}
          </li>
        ))}
      </ul>
    );
  } else if (Array.isArray(data)) {
    return (
      <ul className={styles.list}>
        {data.map((item, index) => (
          <li key={index}>{renderData(item)}</li>
        ))}
      </ul>
    );
  } else {
    return data;
  }
}

function JsonRenderer({ data, fieldsToRender }) {

  function formatFieldName(fieldName) {
    return fieldName.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <div>
      {data &&
        Object.keys(data).map((key, index) =>  {
            if(data[key].length > 0){
              return (
                fieldsToRender.includes(key) && (
                  <div className={styles.dataContainer} key={index}>
                    <h2 className={styles.heading}>{formatFieldName(key)}</h2>
                      {renderData(data[key], fieldsToRender)}
                  </div>
                )
              )
            }
            return null
          }
        )}
    </div>
  );
}


export default function FacultyInfo() {
  
  const location = useLocation();
  // const [info, setInfo] = useState("");
  const [redirect, setRedirect] = useState(false);

  const [data, setData] = useState(null);
  const baseUrl = "https://cse.spit.ac.in/api"

  const fieldsToIterate = ['Post Ph.D Collaborative Research Completed','Pre Ph.D Collaborative Research Completed','Experiences','Achievements','researches', 'Visiting Faculties', 'Certifications','Patents', 'Interests', 'Affiliations','National journals','International journals','National conferences','International conferences','National Paper Publication','International Paper Publication','Research Grant Project','Courses/Workshop/Seminar conducted','Industrial Training Workshop','Paper Presented', 'Books Published', 'Accomplishments', 'Major Assignments', 'System Software Exposure', 'Course Taught', 'Service', 'Activities'];

  const faculty_id = location.pathname.split("/")[2]

  const fetchData = async () => {
    try {
      // console.log(`${baseUrl}/endpoints/faculty_details?id=${faculty_id}`);
      const response = await axios.get(`${baseUrl}/endpoints/faculty_details`, {
        params: {
          id: faculty_id
        }
      });
      setData(response.data);
      // console.log("Data = ",data);
    } catch (error) {
      // console.error("Error fetching data:", error);
      setRedirect(true)
    }
  };

  useEffect(() => {
    fetchData();
  }, [redirect]);

  if (data === null) {
    return (
      <div>{redirect == true && <Navigate to="/404-page-not-found" />}loading</div>
    );
  } else {
  return (
    <div>
      {data != null && (
        <>
          <div className={styles.topBanner}>
            <span className={styles.bannerTitle}>
              {" "}
              Faculty Info - {data.name}{" "}
            </span>
          </div>
          <div className={styles.facultyinfo}>
            <Link to="/faculty" className={styles.goBack}>
              <span className={styles.goBackText}>
                {" "}
                <BsArrowLeft className={styles.backIcon} /> Back to List of
                Faculties{" "}
              </span>
            </Link>
            
            <div className={styles.card}>
              <div className={styles.imgContainer}>
                <img className={styles.img} src={`data:image/jpeg;base64, ${data.image}`} alt="" />
              </div>
              <div className={styles.info}>
                <div className={styles.name}>
                  <div className={styles.title}>{data.name}</div>
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>
                    <strong className={styles.designation}>
                      {data.designation}
                    </strong>{" "}
                    <br />
                    <div className={styles.education}>
                      {data.educations &&
                        Object.values(data.educations).map((item) => (
                          <span key={item}>
                            {item}
                            <br />
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={styles.resumeButton}>{data.resume && (
              <a
                className={styles.resumeBtn}
                href={baseUrl+'/api/files/'+data.resume.replace('resources/', '')}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                View Resume{" "}
              </a>
            )}</div>
              </div>
            </div>

            <div className={styles.mainData}>
{/*here*/}
<JsonRenderer data={data} fieldsToRender={fieldsToIterate} />

              {/*info.data &&
                Object.keys(info.data).map((key, index) => {
                  return (
                    <div className={styles.dataContainer} key={index}>
                      <h2 className={styles.heading}> {key} </h2>
                      <ul className={styles.list}>
                        {Object.values(info.data[key]).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  );
                })*/}
                

              <div className={styles.mainData}>
                <div className={styles.dataContainer}>
                  <h2 className={styles.heading}> Contact Information </h2>
                  <div className={styles.contactInfo}>
                      {data.email &&
                        <span>
                          <b>Email :</b> {data.email}
                          <br />
                        </span>
                      }
                      {data.ext &&
                        <span>
                          <b>Extension :</b> {data.ext}
                          <br />
                        </span>
                      }
                      
                      {data.telephone &&
                        <span>
                          <b>Telephone :</b> {data.telephone}
                          <br />
                        </span>
                      }
                      {data.room &&
                        <span>
                          <b>Room :</b> {data.room}
                          <br />
                        </span>
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
}
